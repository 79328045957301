import React from "react";
import { RxDashboard } from "react-icons/rx";
import { Link } from "react-router-dom";
import { HiOutlineUserGroup } from "react-icons/hi";
import { AiOutlineSetting } from "react-icons/ai";
import { FcSerialTasks, FcMoneyTransfer } from "react-icons/fc";
import { MdPlaylistAdd, MdPlaylistAddCheck } from "react-icons/md";


const AdminSideBar = ({ active }) => {
  return (
    <div className="w-full h-[90vh] bg-[#0504aa] shadow-sm overflow-y-scroll sticky top-0 left-0 z-10 text-white">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link to="/admin/dashboard" className="w-full flex items-center">
          <RxDashboard
            size={20}
            color={`${active === 1 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-[yellow]" : ""
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

     
      <div className="w-full flex items-center p-4">
        <Link to="/admin-users" className="w-full flex items-center">
          <HiOutlineUserGroup
            size={20}
            color={`${active === 2 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 2 ? "text-[yellow]" : ""
            }`}
          >
            All Users
          </h5>
        </Link>
      </div>

      
      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-tasks"
          className="w-full flex items-center"
        >
          <MdPlaylistAddCheck
            size={20}
            color={`${active === 3 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 3 ? "text-[yellow]" : ""
            }`}
          >
            All Tasks
          </h5>
        </Link>
      </div>


      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-withdraw-request-workers"
          className="w-full flex items-center"
        >
          <FcMoneyTransfer
            size={20}
            color={`${active === 4 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 4 ? "text-[yellow]" : ""
            }`}
          >
            All Withdrawals
          </h5>
        </Link>
      </div>



      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-create-task"
          className="w-full flex items-center"
        >
          <MdPlaylistAdd
            size={20}
            color={`${active === 5 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 5 ? "text-[yellow]" : ""
            }`}
          >
            Create Task
          </h5>
        </Link>
      </div>


      <div className="w-full flex items-center p-4">
        <Link
          to="/admin-deposit-request-workers"
          className="w-full flex items-center"
        >
          <FcMoneyTransfer
            size={20}
            color={`${active === 6 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 6 ? "text-[yellow]" : ""
            }`}
          >
            All Deposits
          </h5>
        </Link>
      </div>

      
      <div className="w-full flex items-center p-4">
        <Link
          to="/profile"
          className="w-full flex items-center"
        >
          <AiOutlineSetting
            size={20}
            color={`${active === 7 ? "yellow" : ""}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 7 ? "text-[yellow]" : ""
            }`}
          >
            Settings
          </h5>
        </Link>
      </div>

    </div>
  );
};

export default AdminSideBar;
