

// navigation Data
export const navItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Package",
      url: "/packages",
    },
    {
      title: "contact",
      url: "/contact",
    },
    
    ];


  // Our Service Categories data
  export const serviceCategoriesData = [
    {
      id: 1,
      title: "Art/Graphics",
      subTitle: "",
      href: "/graphics",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 2,
      title: "Branding",
      subTitle: "",
      href: "/branding",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 3,
      title: "Social Media Marketing",
      subTitle: "",
      href: "/social-media-marketing",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 4,
      title: "Web/Mobile App Dev.",
      subTitle: "",
      href: "/web-and-mobile-app-dev",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 5,
      title: "Product Testing",
      subTitle: "",
      href: "/product-testing",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 6,
      title: "Transcription",
      subTitle: "",
      href: "#",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 7,
      title: "Data Analytics",
      subTitle: "",
      href: "#",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
    {
      id: 8,
      title: "Freelance Services",
      subTitle: "",
      href: "#",
      image_Url:
        "https://www.nicepng.com/png/detail/11-119834_colorful-dots-png-circle.png",
    },
  ];


  // Freelance Categories data
  export const freelanceCategoriesData = [
    {
      id: 1,
      title: "Art/Painter",
      },
      {
        id: 2,
        title: "UI/UX Design",
      },
      {
        id: 3,
        title: "Copywriter",
      },
      {
        id: 4,
        title: "SEO Expert",
      },
      {
        id: 5,
        title: "Photographer",
      },
      {
        id: 6,
        title: "Accountant",
      },
      {
        id: 7,
        title: "Human Resource Manager",
      },
      {
        id: 8,
        title: "Public Relations Executive",
      },
      {
        id: 9,
        title: "Videographer",
      },
      {
        id: 10,
        title: "Programmer",
      },
      {
        id: 11,
        title: "Social Media Specialist",
      },
      {
        id: 12,
        title: "Typist/Data Entry",
      },
      {
        id: 13,
        title: "Transcriber/Transcriptionist",
      },
      {
        id: 14,
        title: "Event Manager",
      },
      {
        id: 15,
        title: "Cyber Security Expert",
      },
      {
        id: 16,
        title: "Business Consultant",
      },
      {
        id: 17,
        title: "Graphic Designer",
      },
      {
        id: 18,
        title: "Others",
      },
    
  ];
  
    
  // General categories data
  export const categoriesData = [
    {
      id: 1,
      title: "Graphics Design",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 2,
      title: "Branding",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 3,
      title: "Social Media Marketing",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 4,
      title: "Web/Mobile App Dev.",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 5,
      title: "Product Testing",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 5,
      title: "Social Media Evaluation",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 6,
      title: "Academic Help",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 7,
      title: "Proofreading",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 8,
      title: "Translation",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 9,
      title: "Video Editing",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 10,
      title: "Purchase Assistance",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 11,
      title: "Tech Assistance",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 12,
      title: "Manual Labour",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 12,
      title: "Payments",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 12,
      title: "Transcription",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 12,
      title: "Freelance Services",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 13,
      title: "Video Promo",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 14,
      title: "Survey",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 14,
      title: "Audio Promo",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 15,
      title: "Data Entry",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 16,
      title: "App Developement",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 17,
      title: "Web Developement",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 18,
      title: "Software",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 19,
      title: "Course",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
    {
      id: 20,
      title: "Free",
      subTitle: "",
      href: "#",
      image_Url:
        "https://res.cloudinary.com/totlesoft/image/upload/v1697847656/5_sdexs2.png",
    },
  ];
   
  
  export const footerProductLinks = [
    {
      name: "About us",
      link: "/"
    },
    {
      name: "packages",
      link: "/packages"
    },
  ];
  
  export const footercompanyLinks = [
    {
      name: "Events",
      link: '/ads/events'
    },
    {
      name: "Upgrade",
      link: '/code-vendors'
    },
    {
      name: "Invest",
      link: '/profile'
    },
    {
      name: "Buy/Sell Crypto",
      link: '#'
    },
    {
      name: "Cheap Airtime/Data",
      link: 'https://sabuss.com/totlesoftvtu3'
    },
    {
      name: "Rewards",
      link: "/rewards-earning-structure"
    },
    
    
  ];
  
  export const footerSupportLinks = [
    {
      name: "Contact Us",
      link: "/contact"
    },
    {
      name: "(c) 2024 Eonix All rights reserved",
      link: "#"
    },
  ];
  
