import React from 'react'
import GlobalHeader from '../components/GlobalHeader';
import GlobalFooter from "../components/GlobalFooter";
import UserWithdrawMoneyMonthly from "../components/UserWithdrawMoneyMonthly";

const UserWithDrawMoneyMonthlyPage = () => {
  return (
    <div>
    <GlobalHeader />
    <div className="flex items-start justify-between w-full">
      <UserWithdrawMoneyMonthly />
    </div>
    <GlobalFooter />
  </div>
  )
}

export default UserWithDrawMoneyMonthlyPage