import React, { useEffect, useState } from "react";
import { Country } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { createTask } from "../../redux/actions/task";
import { categoriesData } from "../../static/data";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Spinner from "../Spinner/Spinner";


const CreateTask = () => {
  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.tasks);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  
  
  const [targetedURL, setTargetedURL] = useState("");

  const [facebookURL, setFacebookURL] = useState("");
  const [twitterURL, setTwitterURL] = useState("");
  const [instagramURL, setInstagramURL] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [tiktokURL, setTiktokURL] = useState("");
  const [whatsAppGroupURL, setWhatsAppGroupURL] = useState("");


  const [spotifyURL, setSpotifyURL] = useState("");
  const [boomPlayURL, setBoomPlayURL] = useState("");
  const [soundCloudURL, setSoundCloudURL] = useState("");
  const [telegramURL, setTelegramURL] = useState("");
  const [googleURL, setGoogleURL] = useState("");
  const [trustpilotURL, setTrustpilotURL] = useState("");
    
  const [targetedCountry, setTargetedCountry] = useState("");

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    
  }, [dispatch, error]);  


  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

    const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("targetedURL", targetedURL);
    newForm.append("facebookURL", facebookURL);
    newForm.append("twitterURL", twitterURL);
    newForm.append("instagramURL", instagramURL);
    newForm.append("youtubeURL", youtubeURL);
    newForm.append("linkedinURL", linkedinURL);
    newForm.append("tiktokURL", tiktokURL);
    newForm.append("whatsAppGroupURL", whatsAppGroupURL);
    newForm.append("spotifyURL", spotifyURL);
    newForm.append("boomPlayURL", boomPlayURL);
    newForm.append("soundCloudURL", soundCloudURL);
    newForm.append("telegramURL", telegramURL);
    newForm.append("googleURL", googleURL);
    newForm.append("trustpilotURL", trustpilotURL);
    newForm.append("targetedCountry", targetedCountry);
    newForm.append("userId", user._id);
    dispatch(
      createTask({
        name,
        description,
        targetedURL,
        facebookURL,
        twitterURL,
        instagramURL, 
        youtubeURL, 
        linkedinURL, 
        tiktokURL, 
        whatsAppGroupURL,
        spotifyURL,
        boomPlayURL,
        soundCloudURL,
        telegramURL,
        googleURL,
        trustpilotURL,
        targetedCountry,
        userId: user._id,
        images
      })
    );
    setIsLoading(false);
    toast.success("Task created successfully!");
    navigate("/admin/dashboard");
    window.location.reload();
  };

  
  

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-full items-center ml-5 py-5 px-5">
      <h5 className="text-[30px] font-Poppins">Create Task</h5>
      
      <div className="w-[90%]">
      {/* create task form */}
      <div className="w-full 800px:w-[65%]">
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Title <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="e.g Subscribe to My Youtube Page..."
          />
        </div>
        <br />
        <p>NOTE: You cannot, for example, promote Facebook and Instagram  on one singe task. If you want to grow Facebook and Intagram or any other of your social handles, then you are to create separate tasks each.</p>
        <br />
        <div>
          <label className="pb-2">
            Full Task Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your task description..."
          ></textarea>
        </div>            
            
        
        <br />
        <hr />
        <br />
        <label className="pb-2 font-[600]">
            Task Targeted URL
          </label>
        <p>NOTE: You're to provide just a single targeted link. You cannot add two targeted link per task.</p>
        <br />
        
        <div>
          <label className="pb-2">
            Facebook URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="facebookURL"
            value={facebookURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setFacebookURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Twitter URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="twitterURL"
            value={twitterURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTwitterURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Instagram URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="instagramURL"
            value={instagramURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setInstagramURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Youtube URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="youtubeURL"
            value={youtubeURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setYoutubeURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Linkedin URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="linkedinURL"
            value={linkedinURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setLinkedinURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Tiktok URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="tiktokURL"
            value={tiktokURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTiktokURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            WhatsApp Group URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="whatsAppGroupURL"
            value={whatsAppGroupURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setWhatsAppGroupURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Spotify URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="spotifyURL"
            value={spotifyURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSpotifyURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Boomplay URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="boomPlayURL"
            value={boomPlayURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setBoomPlayURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Soundcloud URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="soundCloudURL"
            value={soundCloudURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setSoundCloudURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Telegram URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="telegramURL"
            value={telegramURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTelegramURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />


        <div>
          <label className="pb-2">
            Google URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="googleURL"
            value={googleURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setGoogleURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Trustpilot URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="trustpilotURL"
            value={trustpilotURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTrustpilotURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />

        <div>
          <label className="pb-2">
            Other Campaign Target URL <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="targetedURL"
            value={targetedURL}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTargetedURL(e.target.value)}
            placeholder="Enter target link"
          />
        </div>
        <br />
        <hr />
        <br />

        <div>
              <label
                htmlFor="targetedCountry"
                className="block text-sm font-medium text-blue-700"
              >
                <span className="text-[16px] text-[#000] text-[400]">Targeted Country</span> (Choose "Worldwide" if you want the task to be open to all countries") <span className="text-red-500">*</span>
              </label>
                  <div className="mt-1">
                  <select
                      name=""
                      id=""
                      value={targetedCountry}
                      onChange={(e) => setTargetedCountry(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="" className="block border pb-2">
                       Choose Country
                      </option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                        <option value="Worldwide">Worldwide</option>
                    </select>
                  </div>
             </div>
             <br />
             

        <div>
         <div>
            <label className="pb-2 mt-2">
            Upload Cover <span className="text-red-500">*</span>
          
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3 cursor-pointer" color="#555" />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={i}
                  key={i}
                  alt=""
                  className="h-[120px] w-[120px] object-cover m-2"
                />
              ))}
          </div>
            </div>
            <br />

          
            <div>
            <input
              type="submit"
              value="Create Task"
              className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] rounded-xl bg-[#0504aa] text-[#fff] sm:text-sm"
            />
          </div>
         </div>

        <br />
        </form>
        </div>
        
    </div>
    </div>
    )}
    </>
    
  );
};

export default CreateTask;

