import React from 'react';
import { Link } from "react-router-dom";
import { RiAccountPinCircleFill } from "react-icons/ri";
import { FaChartLine } from "react-icons/fa6";
import { GrMoney } from "react-icons/gr";
import { FaUserFriends, FaHome } from "react-icons/fa";
import { TbMoneybag } from "react-icons/tb";

const GlobalFooter = () => {

    return (
        <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
        
          <div className="w-full flex justify-between gap-2 mt-4">
           <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
              <div className="w-full bg-[#02066f] py-4 px-2 rounded-2xl flex justify-around">
              <Link to="/">
              <button className="flex flex-col items-center gap-1">
              <FaHome size={20} color="#fff" />
                  <span className="text-gray-300">Home</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/friends">
                <button className="flex flex-col items-center gap-1">
                <FaUserFriends size={20} color="#fff" />
                  <span className="text-gray-300">Friends</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/packages">
                <button className="flex flex-col items-center gap-1">
                <TbMoneybag size={20} color="#fff" />
                  <span className="text-gray-300">Asset</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/incomes">
                <button className="flex flex-col items-center gap-1">
                <FaChartLine size={20} color="#fff" />
                  <span className="text-gray-300">Income</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/profile">
                <button className="flex flex-col items-center gap-1">
                <RiAccountPinCircleFill size={20} color="#fff" />
                  <span className="text-gray-300">Account</span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
    )
}

export default GlobalFooter;