import React, { useEffect, useState } from "react";
import styles from "../../../styles/styles";


const Hero2 = () => {
      
  return (
    <>
   <div className="mt-2 pb-2">
    <br />
      <div
        className={`relative min-h-[30vh] 800px:min-h-[40vh] w-full bg-no-repeat ${styles.noramlFlex}`}>
        <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>
          <h1
            className={`!text-[20px] leading-[0.9] 800px:text-[80px] text-[#0504aa] font-[600]`}
          >
            Our Go<span className="text-[#7BC1FA]">als</span>
          </h1>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          * Create a seamless platform  for individuals to earn excessive income daily with just their smartphone and internet connection and also get paid instantly.
          </p>
          
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          * Enable every EONIX user to effortlessly generate passive income from the comfort of their home.
          </p>
          <br />

          <h1
            className={`!text-[20px] leading-[0.9] 800px:text-[80px] text-[#0504aa] font-[600]`}
          >
            Ensuring Your Financ<span className="text-[#7BC1FA]">ial Stability</span>
          </h1>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          * We guarantee regular returns on your investments, providing financial stability and peace of mind.
          </p>
          
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          *  Transparency and Trust.
          </p>

          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          *  We prioritize transparency in our operations. By investing with us, you agree to our Terms and Conditions and Privacy Policy.
          </p>
          <br />
  
          </div>
       </div>
       </div>
    </>
  );
};

export default Hero2;
