import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import logom from '../../Assests/images/login-logo.png';
//import { loadUser } from "../../redux/actions/user";
//import { useDispatch } from "react-redux";



const Login = () => {
  
  const navigate = useNavigate();
  //const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  

 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    await axios
      .post(
        `${server}/user/login-user`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setIsLoading(false);
        toast.success("Welcome!");
        navigate("/");
        window.location.reload(true);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <>
      <div className="min-h-screen relative bg-[#0504aa] bg-cover bg-center flex flex-col justify-center items-center sm:px-6 lg:px-8"
      style={{ backgroundImage: "url('https://i.ibb.co/SVKzWWb/bg111.jpg')" }}
      >
      <div className="sm:mx-auto sm:w-full sm:max-w-md 800px:w-full">
      <img
        src={logom}
        width='120px'
        alt=""
        />
      </div>
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-4 px-4 sm:rounded-lg sm:px-10">

        <div>
              <h3
                className="group relative w-full h-[40px] flex justify-center mb-4 py-2 px-4 border border-transparent !text-6x1 font-medium rounded-md text-[#fff]"
              >
                Login
              </h3>
            </div>

            <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <div className="mt-1">
                <input
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full bg-[#fff] bg-opacity-40 px-3 py-2 rounded-xl placeholder-[#000] border border-[#00B8E5] sm:text-sm"
                />
              </div>
            </div>
            <div>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full bg-[#fff] bg-opacity-40 px-3 py-2 rounded-xl placeholder-[#000] border border-[#00B8E5] sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            
            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-xl text-[#000] bg-[#00B8E5]"
              >
               Submit
              </button>
            </div>
            
            <div className={`${styles.noramlFlex} w-full text-center`}>
              <h4 className="text-[#fff]">Have no account?</h4>
              <Link to="/sign-up" className="text-[#00B8E5] pl-2">
                sign_up
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
    )}
    </>
    
  );
};

export default Login;


