import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { AiOutlineEye, AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { getAllTasks } from "../../redux/actions/task";
import { useState } from "react";
import Spinner from "../Spinner/Spinner";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const AllTasks = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${server}/task/admin-all-tasks`, {withCredentials: true}).then((res) => {
        setData(res.data.tasks);
        setIsLoading(false);
    })
  }, []);


  const handleDelete = async (id) => {
    await axios
    .delete(`${server}/task/delete-user-task/${id}`, { withCredentials: true })
    .then((res) => {
      toast.success(res.data.message);
      window.location.reload();
    });

  dispatch(getAllTasks());
  };

  const columns = [
    { field: "id", headerName: "Task Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
        field: "taskStatus",
        headerName: "Task Status",
        minWidth: 180,
        flex: 1.4,
      },
    {
      field: "targetedCountry",
      headerName: "Access",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
           <Button onClick={() => setTaskId(params.id) || setOpen(true)}>
              <AiOutlineDelete size={20} />
            </Button>
            <Link to={`/task/${params.id}`}>
              <Button>
                <AiOutlineEye size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  data &&
  data.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        taskStatus: item.taskStatus,
        targetedCountry: item.targetedCountry,
        });
    });

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-full flex justify-center py-5 px-6">
      <div className="w-[95%]">
        <h3 className="text-[30px] font-Poppins-600 pb-2">All Tasks</h3>
      <div className="w-full mx-6 pt-1 mt-10 bg-white">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
        {open && (
          <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
            <div className="w-[60%] 800px:w-[40%] min-h-[20vh] bg-white rounded shadow p-5">
              <div className="w-full flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you wanna delete this task?
              </h3>
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] mr-4`}
                  onClick={() => setOpen(false)}
                >
                  cancel
                </div>
                <div
                  className={`${styles.button} text-white text-[18px] !h-[42px] ml-4`}
                  onClick={() =>  setOpen(false) || handleDelete(taskId)}
                >
                  confirm
                </div>
              </div>
            </div>
          </div>
        )}
        </div>
        </div>
    )}
        
    </>
  );
};

export default AllTasks;
