import React from 'react';
import GlobalHeader from '../components/GlobalHeader';
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import AllDeposits from "../components/Admin/AllDeposits";

const AdminDashboardDeposits = () => {
  return (
    <div>
    <GlobalHeader />
    <div className="w-full flex">
      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <AdminSideBar active={6} />
        </div>
         <AllDeposits />
      </div>
    </div>
  </div>
  )
}

export default AdminDashboardDeposits;