import React from 'react'
import GlobalHeader from '../components/GlobalHeader';
import GlobalFooter from "../components/GlobalFooter";
import UserWithdrawMoney from "../components/UserWithdrawMoney";

const UserWithDrawMoneyPage = () => {
  return (
    <div>
    <GlobalHeader />
    <div className="flex items-start justify-between w-full">
      <UserWithdrawMoney />
    </div>
    <GlobalFooter />
  </div>
  )
}

export default UserWithDrawMoneyPage