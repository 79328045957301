export const sliderData = [
    {
      image1: "https://i.ibb.co/mbpcCDW/dep1.jpg",
      image2: "https://i.ibb.co/mbpcCDW/dep1.jpg", 
      heading: "UHRS",
      desc: "Join the TotleSoft Workforce and get ready for more interesting micro jobs/tasks coming soon. At TotleSoft you earn while learning and completing tasks is fun.",
    },
    {
      image1: "https://i.ibb.co/Yd9yG7D/dep2.jpg",
      image2: "https://i.ibb.co/Yd9yG7D/dep2.jpg", 
      heading: "Paid Survey Studies",
      desc: "Get paid for your time and honest thoughts on product and services by choice clients. Take part in high paying studies research and earn rewards in cash and crypto",
    },
    {
      image1: "https://i.ibb.co/bvNYhfJ/eNew1.jpg",
      image2: "https://i.ibb.co/bvNYhfJ/eNew1.jpg",
      heading: "Art/NFT",
      desc: "The world itself is but a piece of art... Join TotleSoft Art/NFT Community, lern, explore and earn for your creativity and contribution to art projects.",
    },
    {
      image1: "https://i.ibb.co/F6L4qKQ/eNew2.jpg",
      image2: "https://i.ibb.co/F6L4qKQ/eNew2.jpg",
      heading: "Upto $25 Per Task",
      desc: "Receive upto $25 per task. Work 20-30 hours per week. Receive bonus and pay raise for completing task accurately.",
    },
    {
      image1: "https://i.ibb.co/j8sQyMb/dep5.jpg",
      image2: "https://i.ibb.co/j8sQyMb/dep5.jpg",
      heading: "Flexible Payment Method",
      desc: "Leverage on our flexible withdrawal methods when cashing out... PayPal, Bank Transfer, Crypto, Payoneer, Gift Cards.",
    },
    {
      image1: "https://i.ibb.co/9v0FHzX/dep6.jpg",
      image2: "https://i.ibb.co/9v0FHzX/dep6.jpg",
      heading: "Flexible Payment Method",
      desc: "Leverage on our flexible withdrawal methods when cashing out... PayPal, Bank Transfer, Crypto, Payoneer, Gift Cards.",
    },
    {
      image1: "https://i.ibb.co/M2wKxpQ/dep7.jpg",
      image2: "https://i.ibb.co/M2wKxpQ/dep7.jpg",
      heading: "Flexible Payment Method",
      desc: "Leverage on our flexible withdrawal methods when cashing out... PayPal, Bank Transfer, Crypto, Payoneer, Gift Cards.",
    },
  ];
