import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams,  Link } from "react-router-dom";
import { server } from "../server";


const ActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (activation_token) {
      const sendRequest = async () => {
        await axios
          .post(`${server}/user/activation`, {
            activation_token,
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            setError(true);
          });
      };
      sendRequest();
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {error ? (
        
        <div>Your token is expired! <span><Link to="/login" style={{color: "blue"}}>Login</Link></span></div>
      ) : (
        <p>Your account has been created suceessfully! redirecting... if delayed click <span><Link to="/login" style={{color: "blue"}}>Login</Link></span></p>
       
       )}
    </div>
  );
};

export default ActivationPage;
