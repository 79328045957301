import React from 'react'
import GlobalHeader from '../components/GlobalHeader';
import UserDepositMoney2 from "../components/UserDepositMoney2";
import GlobalFooter from '../components/GlobalFooter';

const UserDepositMoneyPage2 = () => {
  return (
    <div>
    <GlobalHeader />
    <div className="flex justify-center w-full">
      <UserDepositMoney2 />
    </div>
    <GlobalFooter />
  </div>
  )
}

export default UserDepositMoneyPage2