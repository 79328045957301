import React, { useEffect, useState } from "react";
import styles from "../../../styles/styles";
import { useSelector } from "react-redux";
import { freelanceCategoriesData } from "../../../static/data";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../server";
import { Link, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { format } from "date-fns";  // Use date-fns for date formatting and comparison


const Hero = () => {
    
  const { user } = useSelector((state) => state.user);
  const [popUp1Open, setPopUp1Open] = useState(false);
  const [mycontrolData, setMycontrolData] = useState([]);

  
  useEffect(() => {
    axios
      .get(`${server}/mycontrol/get-all-mycontrols`, {
        withCredentials: true,
      })
      .then((res) => {
        setMycontrolData(res.data.mycontrols);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []); 

    
  useEffect(() => {
    // Get the current date in 'yyyy-MM-dd' format
    const currentDate = format(new Date(), 'yyyy-MM-dd');

    // Check if taskCompletionDate exists for the user
    const taskCompletionDate = user && user.hasSubscribedDate;  // Assuming user has `hasSubscribedDate` field
    
    // If no taskCompletionDate (new user or undefined), treat as a new day
    if (!taskCompletionDate || user && user.hasSubscribed === "no") {
      console.log("No task completion date found, assuming new user.");
      setPopUp1Open(true);  // Open the popup for new user
      return;  // Exit early as no need to compare dates
    }

    // If taskCompletionDate exists, format it to 'yyyy-MM-dd'
    const storedTaskDate = format(new Date(taskCompletionDate), 'yyyy-MM-dd');

    console.log(storedTaskDate, currentDate); // Debugging: checking the comparison

    // If taskCompletionDate is not the current date, it's a new day or task hasn't been completed today
    if (user && user.hasSubscribed === "yes" && storedTaskDate !== currentDate) {
      setTimeout(() => {
        setPopUp1Open(true);  // Show the popup if it's a new day
      }, 3000);
    }
  }, [user]);  // Make sure the effect runs when the `user` changes

  const controlPin2 = "123456dan2";

  const mcDataToUse2 = mycontrolData && mycontrolData.find((itii) => itii.controlPin === controlPin2);
  console.log(mcDataToUse2);

  

  const subscribeHandler = async () => {

    const userId = user?._id;

    await axios.put(
                `${server}/user/subscribetotel/${userId}`,
                {
                  hasSubscribed: "yes",
                    },
                { withCredentials: true }
              )
              .then((res) => {
                  setPopUp1Open(false);
                  window.location.reload();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
                setPopUp1Open(false);
              });
        }

  return (
    <>
    {user ? (
      <div className="pb-2">
      <div
        className={`flex min-h-[35vh] 800px:min-h-[40vh] w-full ${styles.noramlFlex}`}>
        <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>
          <h1
            className={`text-[40px] leading-[0.9] 800px:text-[80px] text-[#0504aa] font-[600]`}
          >
            Eo<span className="text-[#7BC1FA]">nix</span>
          </h1>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          Your path to Overflowing Wealth
          </p>
          <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          Eonix is a well structured long term integrated reward-based program backed up by top leaders and professionals all over the nations. 
          The system has been designed to enable individuals and youths  to effortlessly generate passive income from the comfort of their home without stress.
          </p>
          
  
          {popUp1Open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div className={`w-[85%] 800px:w-[50%] min-h-[50vh] p-3`}>
              <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-[#fff] py-4 px-4 shadow-lg sm:rounded-lg sm:px-10">

              <div className="space-y-6 items-center text-center justify-center">
            <div className="flex justify-between px-4">
              <p className="text-[18px] font-[500] mr-6">🚀Announcement!!!</p>
              <RxCross1 size={20} className="cursor-pointer" onClick={() => setPopUp1Open(false)} />
            </div>
            <div>
              <p className="text-[#000] whitespace-pre-line">{mcDataToUse2?.adsDetails === undefined ? "Click the 'Join Now' button bellow to join our official Telegram channel and enjoy amazing gifts and bonuses." : mcDataToUse2.adsDetails}</p>
            </div>

            <div>
              <Link to={mcDataToUse2?.adsLink === undefined ? "https://t.me/eonixcommunity" : mcDataToUse2?.adsLink} target="_blank">
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#0504aa]"
                onClick={subscribeHandler}
              >
               🔗 {mcDataToUse2?.buttonText === undefined ? "Join Now" : mcDataToUse2?.buttonText}
              </button>
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
)}
          
        </div>
       </div>
       </div>
    ) : null}
    </>
  );
};

export default Hero;

/*
style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1697581589/bagim_pqzu9p.jpg)",
      }}

*/