import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import { loadUser } from "../redux/actions/user";
import { AiOutlineDelete } from "react-icons/ai";
import Spinner from "../../src/components/Spinner/Spinner";
import tPay from '../Assests/images/logo3.png';
import { Link } from "react-router-dom";


const UserWithdrawMoneyMonthly = () => {
    const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
 
  const [mycontrolData, setMycontrolData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(50);
  const [isLoading, setIsLoading] = useState(false);
  const bonusBalance = user?.bonusBalance.toLocaleString();
  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    bankCountry: "",
    bankSwiftCode: null,
    bankAccountNumber: null,
    bankHolderName: "",
    bankAddress: "",
  });


  useEffect(() => {
    axios
      .get(`${server}/mycontrol/get-all-mycontrols`, {
        withCredentials: true,
      })
      .then((res) => {
        setMycontrolData(res.data.mycontrols);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const mcDataToUse = mycontrolData && mycontrolData.find((iiti) => iiti.controlPin === "123456dan");
  console.log(mcDataToUse);

  const chris = mcDataToUse && mcDataToUse.isPaymentDay;

      const handleSubmit = async (e) => {
    e.preventDefault();

    const withdrawMethod = {
      bankName: bankInfo.bankName,
      bankCountry: bankInfo.bankCountry,
      bankSwiftCode: bankInfo.bankSwiftCode,
      bankAccountNumber: bankInfo.bankAccountNumber,
      bankHolderName: bankInfo.bankHolderName,
      bankAddress: bankInfo.bankAddress,
    };

    setPaymentMethod(false);

    await axios
      .put(
        `${server}/user/update-payment-methods`,
        {
          withdrawMethod,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Bank Details Set!");
        dispatch(loadUser());
        setBankInfo({
          bankName: "",
          bankCountry: "",
          bankSwiftCode: null,
          bankAccountNumber: null,
          bankHolderName: "",
          bankAddress: "",
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const deleteHandler = async () => {
    await axios
      .delete(`${server}/user/delete-withdraw-method`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Bank Detail removed successfully!");
        dispatch(loadUser());
      });
  };

  const error = () => {
    toast.error("You don't have enough balance to withdraw!");
  };

  
  const amountpvat = Number(withdrawAmount);
  const withUserUserId = user?._id;
      
  const withdrawHandler = async () => {

    if (amountpvat > bonusBalance){
      return toast.error("Balance Insuficient!");
    } 
    setIsLoading(true);
      await axios
        .post(
          `${server}/withdrawuser/create-withdraw-request-user`,
          { amount: amountpvat,
            withUserUserId },
          { withCredentials: true }
        )
        .then((res) => {
          setIsLoading(false);
          toast.success("Withdrawal request sent!");
          window.location.reload();
        });
   
  };  
   

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <>
      {chris == "false" ? <div className="w-full items-center justify-center">
        <h1 className="text-[60px] text-center pt-8 px-8"> 404!</h1>
        <h1 className=" text-[#077f9c] text-[30px] py-8 px-8 text-center"> Not Yet Withdrawal Day</h1>
        </div> : (
        <div className="w-full h-[90vh] p-8">
        <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
        <div className="800px:w-[30%] 800px:h-[300px] bg-[#d8f9ff] w-[90%] h-[70%] rounded flex text-center items-center justify-center flex-col">
          <h5 className="text-[20px] text-[blue]">
            Monthly Salary Wallet: 
          </h5>
          <h2 className="text-[35px] font-[600] pb-1">
          N{bonusBalance}
          </h2>
          <div
            className={`${styles.button} text-white !h-[42px] !rounded`}
            onClick={() => (bonusBalance < 1 ? error() : setOpen(true))}
          >
            Withdraw
          </div>
          <Link to="/profile">
          <h5 className="text-[18px] text-[blue] pt-1 mb-2">
           Cancel 
          </h5>
          </Link>
          <div>
        <img
            src={tPay}
            width='60px'
            alt="totlesoftPay"
            className="mt-8"
          />
        </div>
          </div>
          </div>
        {open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div
              className={`w-[95%] 800px:w-[30%] bg-[#d8f9ff] shadow rounded-xl ${
                paymentMethod ? "h-[80vh] overflow-y-scroll" : "h-[unset]"
              } min-h-[40vh] p-3`}
            >
              <div className="w-full flex justify-end">
                <RxCross1
                  size={25}
                  onClick={() => setOpen(false) || setPaymentMethod(false)}
                  className="cursor-pointer"
                />
              </div>
              {paymentMethod ? (
                <div>
                  <h3 className="text-[22px] font-Poppins text-center font-[600]">
                    Add Bank:
                  </h3>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label>
                        Bank Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        value={bankInfo.bankName}
                        onChange={(e) =>
                          setBankInfo({ ...bankInfo, bankName: e.target.value })
                        }
                        id=""
                        placeholder="Enter your Bank name!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
                    <div className="pt-2">
                      <label>
                        Bank Country <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        value={bankInfo.bankCountry}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankCountry: e.target.value,
                          })
                        }
                        id=""
                        required
                        placeholder="Enter your bank Country!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
                    <div className="pt-2">
                      <label>
                        Bank Swift Code <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        id=""
                        required
                        value={bankInfo.bankSwiftCode}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankSwiftCode: e.target.value,
                          })
                        }
                        placeholder="Enter your Bank Swift Code!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
  
                    <div className="pt-2">
                      <label>
                        Bank Account Number{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name=""
                        id=""
                        value={bankInfo.bankAccountNumber}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankAccountNumber: e.target.value,
                          })
                        }
                        required
                        placeholder="Enter your bank account number!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
                    <div className="pt-2">
                      <label>
                        Bank Holder Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        value={bankInfo.bankHolderName}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankHolderName: e.target.value,
                          })
                        }
                        id=""
                        placeholder="Enter your bank Holder name!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
  
                    <div className="pt-2">
                      <label>
                        Bank Address <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        required
                        id=""
                        value={bankInfo.bankAddress}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            bankAddress: e.target.value,
                          })
                        }
                        placeholder="Enter your bank address!"
                        className={`${styles.input} mt-2`}
                      />
                    </div>
  
                    <button
                      type="submit"
                      className={`${styles.button} mb-3 text-white`}
                    >
                      Add
                    </button>
                  </form>
                </div>
              ) : (
                <>
                  <h3 className="text-[22px] font-Poppins">
                    Bank Details:
                  </h3>
  
                  {user && user?.withdrawMethod ? (
                    <div>
                      <div className="800px:flex w-full justify-between items-center mb-2">
                        <div className="800px:w-[50%]">
                          <h5>Account Number: {user?.withdrawMethod.bankAccountNumber}</h5>
                          <h5>Bank Name: {user?.withdrawMethod.bankName}</h5>
                          <h5>Holder's Name: {user?.withdrawMethod.bankHolderName}</h5>
                          <h5>Bank Country: {user?.withdrawMethod.bankCountry}</h5>
                        </div>
                      </div>
                      <div className="800px:w-[50%]">
                          <AiOutlineDelete
                            size={25}
                            className="cursor-pointer"
                            onClick={() => deleteHandler()}
                          /> 
                        </div>
                        <h5 className="text-[15px]">Change Account</h5>
                      <br />
                      <h5 className="text-[18px] text-[blue]">Salary Balance:</h5>
                      <h2 className="text-[35px] font-[600] pb-1">N{bonusBalance}</h2>
                      <br />
                      <h5 className="text-[18px] text-[blue] mb-2">Withdrawal Amount:</h5>
                      <div className="800px:flex w-full items-center">
                        <input
                          type="number"
                          placeholder="Amount..."
                          value={withdrawAmount}
                          onChange={(e) => setWithdrawAmount(e.target.value)}
                          className="800px:w-[100px] w-[full] border 800px:mr-3 p-1 rounded"
                        />
                      </div>
                      <br />
                      
                     <div
                        className={`${styles.button} !h-[42px] text-white mb-2`}
                        onClick={withdrawHandler}
                        >
                          Withdraw
                        </div>
                        <div>
                        <br />
                        <img
                        src={tPay}
                        width='60px'
                        alt="totlesoftPay"
                        />
                        </div>
                    </div>
                  ) : (
                    <div className="items-center justify-center w-full">
                      <p className="text-[14px] text-[red] pt-2">
                        Bank Details Not Set (This must be set in other to procceed!)
                      </p>
                      <div className="w-full flex items-center mt-4">
                        <div
                          className={`${styles.button} !bg-[#0504aa] text-[#fff] text-[16px] mt-4 mb-4`}
                          onClick={() => setPaymentMethod(true)}
                        >
                          Add Bank
                        </div>
                      </div>
                     
                      <br />

                      <div>
                        <img
                        src={tPay}
                        width='60px'
                        alt="totlesoftPay"
                        />
                     </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      )}
      </>
      
    )}
    </>
    
  );
};

export default UserWithdrawMoneyMonthly;
