import React, { useState } from "react";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import Spinner from "../components/Spinner/Spinner";
import ProfileSideBar from "../components/Profile/ProfileSidebar";
import ProfileContent from "../components/Profile/ProfileContent";
import { useSelector } from "react-redux";
import GlobalFooter from "../components/GlobalFooter";

const ProfilePage = () => {
  const { loading } = useSelector((state) => state.user);
  const [active, setActive] = useState(1);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Header />
          <div className={`${styles.section} bg-[#d8f9ff] px-4`}>
            <div className="w-full sticky 800px:mt-0 mt-[18%]">
              <ProfileSideBar active={active} setActive={setActive} />
            </div>
            <ProfileContent active={active} />
          </div>
          <GlobalFooter />
        </>
      )}
    </div>
  );
};

export default ProfilePage;
