import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { HiOutlineUserGroup } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import { getAllUsers } from "../../redux/actions/user";
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";

const AdminDashboardMain = () => {
  const dispatch = useDispatch();

  const { users, user } = useSelector((state) => state.user);
  const [mycontrolData, setMycontrolData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isPaymentDay, setIsPaymentDay] = useState("");
  const [packageType, setPackageType] = useState("");
  const [withdrawalLimit, setWithdrawalLimit] = useState(0);

  const [adsDetails, setAdsDetails] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [adsLink, setAdsLink] = useState("");

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);


  useEffect(() => {
    axios
      .get(`${server}/mycontrol/get-all-mycontrols`, {
        withCredentials: true,
      })
      .then((res) => {
        setMycontrolData(res.data.mycontrols);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const controlPin = "123456dan";
  const controlPin2 = "123456dan2";

  const mcDataToUse = mycontrolData && mycontrolData.find((iti) => iti.controlPin === controlPin);
  console.log(mcDataToUse);

  const mcDataToUse2 = mycontrolData && mycontrolData.find((itii) => itii.controlPin === controlPin2);
  console.log(mcDataToUse2);
 
    
  const handleMyControlUpdate = async () => {
    setIsLoading(true);
    await axios
      .put(`${server}/mycontrol/update-mycontrol/${controlPin}`,{
        updatorEmail: user?.email,
        isPaymentDay: isPaymentDay,
        packageType: packageType,
        withdrawalLimit: withdrawalLimit,
      },{withCredentials: true})
      .then((res) => {
        setIsLoading(false);
        toast.success("Update Successful!");
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
      });
  };


  const handleMyControlUpdate2 = async () => {
    setIsLoading(true);
    await axios
      .put(`${server}/mycontrol/update-mycontrol2/${controlPin2}`,{
        updatorEmail: user?.email,
        adsDetails: adsDetails,
        buttonText: buttonText,
        adsLink: adsLink,
      },{withCredentials: true})
      .then((res) => {
        setIsLoading(false);
        toast.success("Update Successful!");
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
      });
  };

     
  return (
   <>
    {
      isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full py-4 px-6">
        <h3 className="text-[30px] font-Poppins-700 pb-2">Admin Dashboard</h3>

        <div className="w-full block 800px:flex items-center justify-between">
           
            
          <div className="w-full mb-4 800px:w-[32%] min-h-[20vh] bg-white shadow rounded px-2 py-5">
            <div className="flex items-center">
              <HiOutlineUserGroup
                size={25}
                className="mr-2"
                fill="#00000085"
              />
              <h3
                className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
              >
                All Users
              </h3>
            </div>
            <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{users && users.length}</h5>
            <Link to="/admin-users">
              <h5 className="pt-4 pl-2 text-[#077f9c]">View Users</h5>
            </Link>
          </div>
        </div>
        <br />

       <section>
            <div className="w-full p-4 block 800px:flex">
              <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#dff2ff] shadow rounded px-3 py-5">
                <div className="flex">
                <h3 className="text-[30px] font-Poppins-700 pb-2">Payment Control Pannel</h3>
                 </div><br />
               
                <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Event Name:</label>
                      <h5 className={`${styles.input} !w-[95%] mb-4 800px:mb-0 font-[600] !text-[#0504aa]`}>
                        {!mcDataToUse ? "No Name" : mcDataToUse.name}
                      </h5>
                      </div>
                    </div>

                    <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Authorizer's Email:</label>
                      <h5 className={`${styles.input} !w-[95%] mb-4 800px:mb-0 font-[600] !text-[#0504aa]`}>
                        {user?.email}
                      </h5>
                    </div>
                    </div>
                  
                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">It's Pay Day</label>
                      <div className="mt-1">
                        <select
                        name="isPaymentDay"
                        onChange={(e) => setIsPaymentDay(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />

                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Package Type:</label>
                      <div className="mt-1">
                        <select
                        name="packageType"
                        onChange={(e) => setPackageType(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="none">none</option>
                        <option value="BRAZE">BRAZE</option>
                        <option value="BRONZE">BRONZE</option>
                        <option value="SILVER">SILVER</option>
                        <option value="GOLD">GOLD</option>
                        <option value="DIAMOND">DIAMOND</option>
                        <option value="EMERALD">EMERALD</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />


                  <div className="w-full 800px:flex block pb-3">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Withdrawal Limit:</label>
                      <div className="mt-1">
                        <select
                        name="withdrawalLimit"
                        onChange={(e) => setWithdrawalLimit(e.target.value)}
                        className="w-[200px] h-[35px] border rounded"
                        >
                        <option value="Choose">Choose</option>
                        <option value="2100">2100</option>
                        <option value="4200">4200</option>
                        <option value="10500">10500</option>
                        <option value="21000">21000</option>
                        <option value="52500">52500</option>
                        <option value="105000">105000</option>
                        </select>
                      </div>
                    </div>           
                  </div>
                  <br />


                  <br />
                <button 
                className={`${styles.button} !bg-[#0504aa] text-[#fff] justify-center items-center text-center`}
                onClick={handleMyControlUpdate}
                >
                   Update
                </button>
               <br />                 
                
    
                </div>
              </div>
              
            </section>
            <br />


            <section>
            <div className="w-full p-4 block 800px:flex mt-4 mb-9">
              <div className="w-full mb-4 800px:w-[80%] min-h-[20vh] bg-[#dff2ff] shadow rounded px-3 py-5">
                <div className="flex">
                <h3 className="text-[30px] font-Poppins-700 pb-2">Ads Control Pannel</h3>
                 </div><br />
               
                <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Event Name:</label>
                      <h5 className={`${styles.input} !w-[95%] mb-4 800px:mb-0 font-[600] !text-[#0504aa]`}>
                        {!mcDataToUse2 ? "No Name" : mcDataToUse2.name}
                      </h5>
                      </div>
                    </div>

                    <div className="w-full 800px:flex block pb-3 mb-4">
                    <div className=" w-[100%] 800px:w-[50%]">
                      <label className="block pb-2">Authorizer's Email:</label>
                      <h5 className={`${styles.input} !w-[95%] mb-4 800px:mb-0 font-[600] !text-[#0504aa]`}>
                        {user?.email}
                      </h5>
                    </div>
                    </div>


                    <br />
        <div>
          <label className="pb-2">
          Ads Details:
          </label>
          <textarea
            cols="30"
            rows="8"
            type="text"
            name="adsDetails"
            value={adsDetails}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setAdsDetails(e.target.value)}
            placeholder="Enter ads details"
          ></textarea>
        </div> 

        <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue] whitespace-pre-line`}>{mcDataToUse2?.adsDetails === undefined ? "" : mcDataToUse2?.adsDetails}</h5>

                  <br />

                  <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Button Text:</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={buttonText}
                    onChange={(e) => setButtonText(e.target.value)}
                  />
                </div>
                <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{mcDataToUse2?.buttonText === undefined ? "" : mcDataToUse2?.buttonText}</h5>
                  <br />


                  <br />

                  <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Button Text:</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    value={adsLink}
                    onChange={(e) => setAdsLink(e.target.value)}
                  />
                </div>
                <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{mcDataToUse2?.adsLink === undefined ? "" : mcDataToUse2?.adsLink}</h5>
                  <br />
                  <br />


                  <br />
                <button 
                className={`${styles.button} !bg-[#0504aa] text-[#fff] justify-center items-center text-center`}
                onClick={handleMyControlUpdate2}
                >
                   Update
                </button>
               <br />                 
                
    
                </div>
              </div>
              
            </section>
            <br />
            <br />
      
        </div>
      )
    }
   </>
  );
};

export default AdminDashboardMain;
