import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import mark from '../../../Assests/images/complete.png';

const TaskCard = ({ data, duser }) => {
   
  
  return (
    <>
    <Link to={`/task/${data._id}`}>
      <div className="w-[300px] h-[450px] bg-[#02066f] rounded-lg shadow-sm p-3 relative cursor-pointer mb-4">
      <img
            src={`${data.images && data.images[0]?.url}`}
            alt=""
            className="w-full h-[290px] object-contain py-1 px-1"
          />
      <h4 className="pb-3 pt-3 font-[500] text-[16px] text-[#fff]">
       {data.name.length > 70 ? data.name.slice(0, 70) + "..." : data.name}
          </h4>
      
          
      <div className="flex justify-between">
      <Link to={`/task/${data._id}`}>
        <div className={`${styles.button} !bg-[#d8f9ff] !text-[#000] !w-[100px] !h-[25px] mb-5`}>
                 <span className="font-[Poppins] text-[15px]">
                    See Details
                 </span>
            </div>
        </Link>

        {data.workers.some(worker => worker.workerId === duser) && <img className="mr-3" src={mark} width={30} height={10} />}
      </div>
        
      </div>
      </Link>
    </>
  );
};

export default TaskCard;
