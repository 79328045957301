import React, { useState, useEffect } from 'react';
import Header from "../components/Layout/Header";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { server } from "../server";
import styles from "../styles/styles";
import axios from "axios";
import HistoryInfo from "../components/Profile/HistoryInfo";
import EhistoryInfo from "../components/Profile/EhistoryInfo";
import logo2 from '../Assests/images/logo2.png';
import GlobalFooter from '../components/GlobalFooter';

const Income = () => {

    const { user } = useSelector((state) => state.user);
    const [withdrawUserUserData, setWithdrawUserUserData] = useState([]);
    const [depositData, setDepositData] = useState([]);
    const [eHistoryData, setEhistoryData] = useState([]);
  
    const [mycontrolData, setMycontrolData] = useState([]);
  
    const withUserUserId = user?._id;
    const depositUserId = user?._id;
   
    useEffect(() => {
      axios
        .get(`${server}/mycontrol/get-all-mycontrols`, {
          withCredentials: true,
        })
        .then((res) => {
          setMycontrolData(res.data.mycontrols);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }, []);
  
      
      useEffect(() => {
          axios
            .get(`${server}/withdrawuser/get-all-withdraw-request-user/${withUserUserId}`, {
              withCredentials: true,
            })
            .then((res) => {
              setWithdrawUserUserData(res.data.withdrawusers);
            })
            .catch((error) => {
              console.log(error.response.data.message);
            });
        }, []);
  
        useEffect(() => {
          axios
            .get(`${server}/depositfb/get-all-deposit-user/${depositUserId}`, {
              withCredentials: true,
            })
            .then((res) => {
              setDepositData(res.data.depositfbs);
            })
            .catch((error) => {
              console.log(error.response.data.message);
            });
        }, []);
  
        useEffect(() => {
          const dataforEarnH = user && user.earningHistories;
          setEhistoryData(dataforEarnH);
        }, []);
  
        
    const availableBalance = user?.availableBalance;
    const bonusBalance = user?.bonusBalance;
      
    const mcDataToUse = mycontrolData && mycontrolData.find((iiti) => iiti.controlPin === "123456dan");
    console.log(mcDataToUse);
  
    
    return (
      <div className={`${styles.section} w-[90%] 800px:w-[80%] mb-5 py-9`}>
  
      <div className="w-full block 800px:flex items-center justify-between px-5">
        <div className="w-full mb-4 800px:w-[30%] h-[30vh] min-h-[25vh] border border-b-[#0504aa] border-r-[#0504aa] border-b-[8px] border-r-[8px] bg-white shadow rounded px-5 py-5">
            <div className="flex items-center justify-center">
            <img
            src={logo2}
            width='25px'
            alt=""
            />
              <h3
                className={`${styles.productTitle} !text-[16px] leading-5 !font-[400] ml-2 !text-[#0504aa]`}
              >
                Main Asset Wallet
                </h3>
            </div>
            <h5 className="text-center pt-2 text-[30px] font-[500]">N{availableBalance}</h5>
            {mcDataToUse && mcDataToUse.isPaymentDay === "false" ? (
              <>
              <h5 className="text-center pt-4 px-[2] text-[#808080]">Withdraw</h5>

              {user && user?.hasDeposited === "yes" ? (
                <h5 className="text-center pt-4 px-[2] text-[#808080]">Deposit</h5>
              ) : (
                <Link to="/account-deposit">
                <h5 className="text-center pt-4 px-[2] text-[#0504aa]">Deposit</h5>
                </Link>
              )}
            </>
            ) : (
              <>
              <Link to="/account-withdraw-assetbalance">
              <h5 className="text-center pt-4 pl-[2] text-[#0504aa]">Withdraw</h5>
            </Link>
  
            {user && user?.hasDeposited === "yes" ? (
                <h5 className="text-center pt-4 px-[2] text-[#808080]">Deposit</h5>
              ) : (
                <Link to="/account-deposit">
                <h5 className="text-center pt-4 px-[2] text-[#0504aa]">Deposit</h5>
                </Link>
              )}
              </>            
            )}
            
          </div>
  
          
  
          <div className="w-full mb-4 800px:w-[30%] h-[30vh] min-h-[25vh] border border-b-[#0504aa] border-r-[#0504aa] border-b-[8px] border-r-[8px] bg-white shadow rounded px-5 py-5">
            <div className="flex items-center justify-center">
            <img
            src={logo2}
            width='25px'
            alt=""
            />
              <h3
                className={`${styles.productTitle} !text-[16px] leading-5 !font-[400] ml-2 !text-[#0504aa]`}
              >
                Monthly Salary Wallet
                </h3>
            </div>
            <h5 className="text-center pt-2 text-[30px] font-[500]">N{bonusBalance}</h5>
            <Link to="/account-withdraw-monthlybalance">
              <h5 className="text-center pt-4 pl-[2] text-[#0504aa]">Withdraw</h5>
            </Link>
            </div>
         
         </div>
          <br />
  
          <div className="w-full">
          <h1 className="text-[20px] pb-3 ml-4 font-[600]">Transaction History</h1>
          </div>
          
  
          <div className="w-full">
          <HistoryInfo
              depositData={depositData}
              withdrawUserUserData={withdrawUserUserData}
          />
          </div>
              
            <br />
            <br />
  
  
            <div className="w-full">
          <h1 className="text-[20px] pb-3 ml-4 font-[600]">Earning History</h1>
          </div>
          
  
          <div className="w-full">
          <EhistoryInfo
              eHistoryData={eHistoryData}
             />
          </div>
              
            <br />
            <br />
  
      </div>
    )
  }


  const IncomePage = () => {
    return (
        <div>
            <Header />
            <Income />
            <GlobalFooter />
        </div>
    )
  }

  export default IncomePage;