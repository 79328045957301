import React, { useState, useEffect } from 'react';
import { server } from "../server";
import { toast } from "react-toastify";
import axios from "axios";

const InvestmentStatus = ({ invstStat, startDate, investmentId, maxDays = 30 }) => {
  const [runningDays, setRunningDays] = useState(0);
  const [status, setStatus] = useState("running");

  // Function to calculate days difference
  const calculateRunningDays = (startDate) => {
    const start = new Date(startDate);
    const now = new Date();
    const timeDiff = now - start;
    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24)); // Convert ms to days
    return daysDiff;
  };

  useEffect(() => {
    // Calculate running days
    const daysPassed = calculateRunningDays(startDate);

    if (daysPassed >= maxDays) {
      setRunningDays(maxDays); // Cap the days to maxDays
      setStatus("ended"); // Update status to ended
      // You may want to update the database here via an API call.
    } else {
      setRunningDays(daysPassed + 1); // +1 because you want Day 1 to be displayed on the first day
    }
  }, [startDate]);


  useEffect(() => {
    if(invstStat !== "ended" && status === "ended"){
        investmentstatusUpdateHandler();
    }
  }, []);


  const investmentstatusUpdateHandler = async () => {

    const taskStatus = status;
   
    await axios
    
          .put(
            `${server}/investment/update-investment-status/${investmentId}`,
            {
                investmentStatus: taskStatus
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("Investment Ended!");
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
  }

  return (
    <div className='w-full items-center text-[#fff]'>
      <h3 className='text-[15px] text-center'>status : {status}</h3>
      <p className='text-[15px] text-center'>Day {runningDays} of {maxDays}</p>
    </div>
  );
};

export default InvestmentStatus;
