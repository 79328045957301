import React, { useEffect, useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import TaskCard from "../components/Route/TaskCard/TaskCard";
import styles from "../styles/styles";
import { useSelector } from "react-redux";
import Spinner from "../components/Spinner/Spinner";
import { useSearchParams, Link } from "react-router-dom";
import GlobalFooter from '../components/GlobalFooter';

const TasksPage = () => {

  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const {allTasks, isLoading} = useSelector((state) => state.tasks);
  const { user } = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  const taskByCountry = allTasks && allTasks.filter((itmee) => itmee.targetedCountry === user?.country || itmee.targetedCountry === "Worldwide");

  useEffect(() => {
    if (categoryData === null) {
      const d = taskByCountry;
      setData(d);
    } else {
      const d =
      taskByCountry && taskByCountry.filter((i) => i.category === categoryData);
      setData(d);
    }
    //    window.scrollTo(0,0);
  }, [taskByCountry]);

  const dtrueUser = user?._id;
   
  return (
    <>
  {
    isLoading ? (
      <Spinner />
    ) : (
      <div>
      <Header />
      <br />
      <br />
      {
        user && user?.role === "Worker" ? (
          <div className={`${styles.section}`}>            

      <div className="w-full block 800px:flex items-center justify-between px-5">
          {data && data.map((i, index) => <TaskCard data={i} key={index} duser={dtrueUser} />)}
        </div>
        {data && data.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            No task found!
          </h1>
        ) : null}
      </div>
        ) : (
          <h1 className="text-center w-full pb-[100px] pl-[10px] pr-[10px] text-[30px]">
            Access Denied! Only Asset Holders can perform task.
          </h1>
          )
      }

<h5 className="mt-9 mb-9">-</h5>

    <GlobalFooter />     
    </div>
    )
  }
  </>
  )
};

export default TasksPage;
