import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "../styles/styles";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import { FaUserFriends } from "react-icons/fa";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Spinner from "../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import tPay from '../Assests/images/logo.png';

const UserFriends = () => {
  
  const { user } = useSelector((state) => state.user);
  const [isvisible, setIsVisible] = useState(false);
  

  const writeTextToClipboard = (text) => {
    text = refLink;
    navigator.clipboard.writeText(text);
    toast.success("Link Copied!");
  };

  const totalReferrers = user?.totalReferrers;
  const refLink = user?.refLink;

  
  return (
    <div className="w-full h-[80vh] p-8">
      <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
      <div className="800px:w-[30%] 800px:h-[95%] bg-[#d8f9ff] w-[90%] h-[95%] rounded flex text-center items-center justify-center flex-col 800px:py-2">
          <div className="flex items-center justify-center">
            <FaUserFriends
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#000]`}
            >
            Referrals Details
             
            </h3>
          </div>

          <div className="flex justify-between w-full">
            <div className="w-[30%] block text-center px-1">
              <h5 className="text-[25px] font-[500] text-[#000]">{totalReferrers}</h5>
              <span className="text-[13px] text-[#000]">Total</span>
            </div>

            <div className="w-[30%] block text-center px-1">
              {user && user.InvestedReferrers === undefined ? <h5 className="text-[25px] font-[500] text-[#000]">0</h5> : <h5 className="text-[25px] font-[500] text-[#000]">{user.InvestedReferrers}</h5>}
              <span className="text-[13px] text-[#000]">Invested</span>
            </div>

            <div className="w-[30%] block text-center px-1">
              {user && user.totalRefEarning === undefined ? <h5 className="text-[25px] font-[500] text-[#000]">0</h5> : <h5 className="text-[25px] font-[500] text-[#000]">{user.totalRefEarning}</h5>}
              <span className="text-[13px] text-[#000]">Total Earning</span>
            </div>
          </div>
          
          <div
          className={`${styles.button} text-white !h-[42px] !rounded-xl !mt-4`}
          onClick={() => setIsVisible(true)}
        >
          Generate Link
        </div>

        {isvisible ? <h5 className="pt-2 pl-[5px] pr-[5px] text-[12px] font-[600]">{refLink}</h5> : null}

        {isvisible ? <h5 onClick={writeTextToClipboard} className="cursor-pointer text-[12px] text-center pl-[2] pr-[2] text-[blue]">Copy</h5> : null}


        <h5 className="w-full text-center text-[16px] font-[500] mt-6 mb-1">Referral Commission</h5>

        <div className="w-full h-[20vh] block px-6 py-4 bg-cover bg-center mt-1 justify-center items-center"
        style={{
          backgroundImage: "url('https://i.ibb.co/SVKzWWb/bg111.jpg')"
        }}
        >
          <div className="flex w-full justify-between mb-4">
            <div className="w-[30%] block text-center bg-[#fff] px-1 rounded-md">
              <span className="text-[10px] text-[#000] mb-1">2k Pack</span>
              <h5 className="text-[16px] text-[#000]">N300</h5>
            </div>

            <div className="w-[30%] block text-center bg-[#fff] px-1 rounded-md">
              <span className="text-[10px] text-[#000] mb-1">4k Pack</span>
              <h5 className="text-[16px] text-[#000]">N600</h5>
            </div>

            <div className="w-[30%] block text-center bg-[#fff] px-1 rounded-md">
              <span className="text-[10px] text-[#000] mb-1">10k Pack</span>
              <h5 className="text-[16px] text-[#000]">N1,500</h5>
            </div>
          </div>


          <div className="flex w-full justify-between">
            <div className="w-[30%] block text-center bg-[#fff] px-1 rounded-md">
              <span className="text-[10px] text-[#000] mb-1">20k Pack</span>
              <h5 className="text-[16px] text-[#000]">N3,000</h5>
            </div>

            <div className="w-[30%] block text-center bg-[#fff] px-1 rounded-md">
              <span className="text-[10px] text-[#000] mb-1">50k Pack</span>
              <h5 className="text-[16px] text-[#000]">N7,500</h5>
            </div>

            <div className="w-[30%] block text-center bg-[#fff] px-1 rounded-md">
              <span className="text-[10px] text-[#000] mb-1">100k Pack</span>
              <h5 className="text-[16px] text-[#000]">N15,000</h5>
            </div>
          </div>
        </div>
       
        <div className="mt-4">
        <img
            src={tPay}
            width='60px'
            alt=""
          />
        </div>

           </div>
        </div>      
    </div>
  );
};

export default UserFriends;
