import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  LoginPage,
  LoginMinerPage,
  SignupPage,
  SignupRefPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  TasksPage,
  STasksPage2,
  BestSellingPage,
  EventsPage,
  FAQPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ProductDetailsPage,
  TaskDetailsPage,
  STaskDetailsPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  OrderDetailsPage,
  TrackOrderPage,
  UserInbox,
  ContactPage,
  GraphicsPage,
  BrandingPage,
  SMEPage,
  WnMAppDevPage,
  OPSPage,
  ShippingPage,
  CareerPage,
  CVPage,
  PrivacyPolicyPage,
  PaystackPaymentPage,
  UserWithDrawMoneyPage,
  TaskTnCPage,
  ProductTestingPage,
  UserTransferMoneyPage,
  CompressorPage,
  UserWCommPage,
  CVendorsPage,
  TFoundationPage,
  ProductAffiliateDetailsPage,
  ViewApplicantsPage,
  AssignWorkerPage,
  UserExchangePage,
  UserInvestPage,
  RCHomePage,
  ArticleDetails,
  CreateArticlePage,
  ProjectWorkPage,
  ViewJobSamplesPage,
  DJobPage,
  DJobDetailsPage,
  SendSamplesPage,
  CVendorApplicationPage,
  TaxInfoPage,
  RWHomePage,
  VideoTasksPage,
  VideoTaskDetailsPage,
  UserInvestDetailPage,
  AcademyPage,
  TestsPage,
  AudioTranscriptionTestPage,
  CoursePage,
  CreateTalentPage,
  TalentPage,
  TalentDetailPage,
  PackageAccessPage,
  EventAdPage,
  MinerFriendsPage,
  UserGigsPage,
  TranscriptionPage,
  SignUpMinerPage,
  MinerAccountPage,
  MinerTasksPage,
  MinerBoostsPage,
  MinerStatsPage,
  MinerLeaguePage,
  IVInvitesPage,
  SignUpMinerRefPage,
  MinerTaskDetailPage,
  MineWhitePaperPage,
  UserDepositMoneyPage,
  UserDepositMoneyPage2,
  UserWithDrawMoneyMonthlyPage,
  PackagesPage,
  UserFriendsPage,
  AdsClicksPage,
  IncomePage,
 } from "./routes/Routes.js";
import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopCreateTask,
  ShopCreateSTask,
  ShopAllProducts,
  ShopAllTasks,
  ShopCreateEvents,
  ShopAllEvents,
  ShopAllCoupouns,
  ShopPreviewPage,
  ShopAllOrders,
  ShopAllSTasks,
  ShopSettingsPage,
  ShopWithDrawMoneyPage,
  ShopInboxPage,
  ShopCreateJob,
  ShopAllDJobs,
  ShopDepositMoneyPage,
  ShopCreateVideoTask,
  ShopAllVideoTasks,
  ShopCreateEventsAd,
} from "./routes/ShopRoutes";
import {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEvents,
  AdminDashboardWithdraw,
  AdminDashboardWithdrawUser,
  AdminDashboardTasks,
  AdminDashboardTransfer,
  AdminDashboardNewCode,
  AdminDashboardUsedCode,
  AdminActionPage,
  AdminDasboardCreateTasks,
  AdminDashboardDeposits,
} from "./routes/AdminRoutes";
import {
  CodeVendorDashboardPage,
  CodeVendorDashboardNewCodes,
  CodeVendorDashboardUsedCodes,
} from "./routes/CodeVendorRoutes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import { loadUser } from "./redux/actions/user";
import { loadSeller } from "./redux/actions/sellers";
import { loadMinerTask } from "./redux/actions/minertask";
import { loadMiner } from "./redux/actions/miner";
import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
import ProtectedCodeVendorRoute from "./routes/ProtectedCodeVendorRoutes.js";
import { ShopHomePage } from "./ShopRoutes.js";
import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import { getAllProducts } from "./redux/actions/product";
import { getAllTasks } from "./redux/actions/task";
import { getAllSTasks } from "./redux/actions/stask";
import { getAllEvents } from "./redux/actions/event";
import { getAllMinerTasks } from "./redux/actions/minertask";
//import axios from "axios";
//import { server } from "./server";



const App = () => {
  
    
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(loadMiner());
    Store.dispatch(loadMinerTask());
    Store.dispatch(getAllProducts());
    Store.dispatch(getAllTasks());
    Store.dispatch(getAllSTasks());
    Store.dispatch(getAllEvents());
    Store.dispatch(getAllMinerTasks());
  }, []);

  return (
    <BrowserRouter>
      <Routes>
            <Route
              path="/payment"
              element={
                <ProtectedRoute>
                  <PaymentPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        <Routes>
        
        <Route path="/birthday/erhuvwuokokoro" element={<IVInvitesPage />} />
        
        <Route path="/login" element={<LoginPage />} />
        
        <Route path="/sign-up" element={<SignupPage />} />

        <Route path="/sign-up/ref/:username" element={<SignupRefPage />} />
        
        <Route
          path="/user/activation/:activation_token"
          element={<ActivationPage />}
        />
        <Route
          path="/seller/activation/:activation_token"
          element={<SellerActivationPage />}
        />
        <Route path="/tasks" element={
          <ProtectedRoute>
            <TasksPage />
            </ProtectedRoute>} />

        <Route path="/videotasks" element={
          <ProtectedRoute>
            <VideoTasksPage />
            </ProtectedRoute>} />

            <Route path="/packages" element={
          <ProtectedRoute>
            <PackagesPage />
            </ProtectedRoute>} />

            <Route path="/incomes" element={
          <ProtectedRoute>
            <IncomePage />
            </ProtectedRoute>} />

            <Route path="/ads-clicks" element={
          <ProtectedRoute>
            <AdsClicksPage />
            </ProtectedRoute>} /> 

            <Route path="/friends" element={
          <ProtectedRoute>
            <UserFriendsPage />
            </ProtectedRoute>} />
                       
        <Route path="/task/:id" element={
          <ProtectedRoute>
            <TaskDetailsPage />
            </ProtectedRoute>} />
        <Route path="/videotask/:id" element={<VideoTaskDetailsPage />} />
        
        
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/contact" element={<ContactPage />} />
       
        <Route path="/image-compressor" element={<CompressorPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/task-submission-terms-and-condition" element={
          <ProtectedRoute>
            <TaskTnCPage />
            </ProtectedRoute>} />
        <Route path="/paystack" element={<PaystackPaymentPage />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        
                
        <Route
          path="/account-withdraw-assetbalance"
          element={
            <ProtectedRoute>
              <UserWithDrawMoneyPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/account-withdraw-monthlybalance"
          element={
            <ProtectedRoute>
              <UserWithDrawMoneyMonthlyPage />
            </ProtectedRoute>
          }
        />
        
        <Route
          path="/account-deposit"
          element={
            <ProtectedRoute>
              <UserDepositMoneyPage2 />
            </ProtectedRoute>
          }
        />
        
        <Route
          path="/dashboard-invest"
          element={
            <ProtectedRoute>
              <UserInvestPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
       <Route
          path="/i/details/:id"
          element={
            <ProtectedRoute>
              <UserInvestDetailPage />
            </ProtectedRoute>
          }
        />
        {/* miner Routes */}

        

        
        {/* shop Routes */}
        <Route path="/shop/preview/:id" element={<ShopPreviewPage />} />
        <Route path="/shop-create" element={<ShopCreatePage />} />
        <Route path="/shop-login" element={<ShopLoginPage />} />
        <Route
          path="/shop/:id"
          element={
            <SellerProtectedRoute>
              <ShopHomePage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <SellerProtectedRoute>
              <ShopSettingsPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-product"
          element={
            <SellerProtectedRoute>
              <ShopCreateProduct />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-uns-task"
          element={
            <SellerProtectedRoute>
              <ShopCreateTask />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-videotask"
          element={
            <SellerProtectedRoute>
              <ShopCreateVideoTask />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-s-task"
          element={
            <SellerProtectedRoute>
              <ShopCreateSTask /> 
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-job"
          element={
            <SellerProtectedRoute>
              <ShopCreateJob /> 
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-orders"
          element={
            <SellerProtectedRoute>
              <ShopAllOrders />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-s-tasks"
          element={
            <SellerProtectedRoute>
              <ShopAllSTasks />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/dashboard-products"
          element={
            <SellerProtectedRoute>
              <ShopAllProducts />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-uns-tasks"
          element={
            <SellerProtectedRoute>
              <ShopAllTasks /> 
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-videotasks"
          element={
            <SellerProtectedRoute>
              <ShopAllVideoTasks /> 
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-job"
          element={
            <SellerProtectedRoute>
              <ShopAllDJobs /> 
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-event"
          element={
            <SellerProtectedRoute>
              <ShopCreateEvents />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-eventAd"
          element={
            <SellerProtectedRoute>
              <ShopCreateEventsAd />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-events"
          element={
            <SellerProtectedRoute>
              <ShopAllEvents />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-coupouns"
          element={
            <SellerProtectedRoute>
              <ShopAllCoupouns />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-withdraw-money"
          element={
            <SellerProtectedRoute>
              <ShopWithDrawMoneyPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-deposit-money"
          element={
            <SellerProtectedRoute>
              <ShopDepositMoneyPage />
            </SellerProtectedRoute>
          }
        />
        {/* Admin Routes */}
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin/action/:id"
          element={
            <ProtectedAdminRoute>
              <AdminActionPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-users"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardUsers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-sellers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardSellers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-orders"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardOrders />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-products"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardProducts />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-tasks"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardTasks />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-create-task"
          element={
            <ProtectedAdminRoute>
              <AdminDasboardCreateTasks />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-transfers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardTransfer />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-events"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardEvents />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-withdraw-request"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardWithdraw />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-withdraw-request-workers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardWithdrawUser />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-deposit-request-workers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardDeposits />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-newcodes"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardNewCode />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-usedcodes"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardUsedCode />
            </ProtectedAdminRoute>
          }
        />
        {/* Code Vendor Routes */}
        <Route
          path="/codevendor/dashboard"
          element={
            <ProtectedCodeVendorRoute>
              <CodeVendorDashboardPage />
            </ProtectedCodeVendorRoute>
          }
        />
        <Route
          path="/codevendor-newcodes"
          element={
            <ProtectedCodeVendorRoute>
              <CodeVendorDashboardNewCodes />
            </ProtectedCodeVendorRoute>
          }
        />
        <Route
          path="/codevendor-usedcodes"
          element={
            <ProtectedCodeVendorRoute>
              <CodeVendorDashboardUsedCodes />
            </ProtectedCodeVendorRoute>
          }
        />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;

// Pystack API test Public Key: pk_test_ce3becdbd917d77292665073fccbc6de12dfa5b8
// Paystack API test Secrete Key: sk_test_197dcdd88d4e5d1126132ade81f082148b981d49 

// live PK - pk_live_32cba88ca2dcc9c45269a49559787aa1a6a55964

// Stripe Secrete Text Key: sk_test_51KrQjzDp9xWz4VfZgzzW2xWz8IJZiILIVU75XXth4zHUqCl3Vpw5wK179kVN1kYSXUC610UlekKn87RMiw2h7ptB00WVVHne4y
// Stripe API test Key: pk_test_51KrQjzDp9xWz4VfZmZMn6yayM6OFy96Y1ZXX4G0QhmrOwBVPOoaJeNAABTMpVuXKEBSaxd7h0Xy7qV7WbmBdDrGW00zQ4iMdMj

