import React, { useState, useEffect } from "react";
import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
  AiOutlineMoneyCollect,
} from "react-icons/ai";


import { FaUserFriends } from "react-icons/fa";
import { GiThreeFriends } from "react-icons/gi";
import { TbMoneybag } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { server } from "../../server";
import styles from "../../styles/styles";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { MdTrackChanges, MdOutlineFolderCopy } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import {
  deleteUserAddress,
  loadUser,
  updatUserAddress,
  updateUserInformation,
} from "../../redux/actions/user";
import { Country, State, City } from "country-state-city";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../Spinner/Spinner";
import HistoryInfo from "../Profile/HistoryInfo";
import EhistoryInfo from "../Profile/EhistoryInfo";
import worker from "../../Assests/images/worker.gif";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import logo2 from '../../Assests/images/logo2.png';
import { CgProfile } from "react-icons/cg";


const ProfileContent = ({ active }) => {
  
  const { user } = useSelector((state) => state.user);
  
  
  return (
    <>
    <div className="mt-[100px] px-4 800px:mt-[50px] bg-[#d8f9ff]">
      {/* Account */}
      {active === 1 && (
        <>

        <div>
          <img
            src={`${user?.avatar?.url}`}
            className="ml-3 w-[60px] h-[60px] rounded-full mr-4"
            alt=""
             />
          </div>
        <br />
                    
            <div className="bg-[#0504aa] text-[#fff] w-full 800px:w-[600px] mt-2 px-4 py-2 rounded-xl">
              <div className="flex justify-between ">
              <h5 className="text-[16px] font-[400] w-[30%]">UID</h5>
              <h5 className="text-[8px] 800px:text-[16px] bg-[#fff] text-[#1c1c1c] w-[60%] px-2 py-1 rounded-xl overflow-x scroll">{user?._id}</h5>
              </div>
            </div>

            <div className="bg-[#0504aa] text-[#fff] w-full 800px:w-[600px] mt-2 px-4 py-2 rounded-xl">
              <div className="flex justify-between">
              <h5 className="text-[16px] font-[400] w-[30%]">Name</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[60%] px-2 py-1 rounded-xl">{user?.name}</h5>
              </div>
            </div>

            <div className="bg-[#0504aa] text-[#fff] w-full 800px:w-[600px] mt-2 px-4 py-2 rounded-xl">
              <div className="flex justify-between">
              <h5 className="text-[16px] font-[400] w-[30%]">Username</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[60%] px-2 py-1 rounded-xl">{user?.username}</h5>
              </div>
            </div>

            <div className="bg-[teal] text-[#fff] w-full 800px:w-[600px] mt-2 px-4 py-2 rounded-xl">
              <div className="flex justify-between">
              <h5 className="text-[16px] font-[400] w-[30%]">Email</h5>
              <h5 className="text-[8px] 800px:text-[16px] bg-[#fff] text-[#1c1c1c] w-[60%] px-2 py-1 rounded-xl overflow-x scroll">{user?.email}</h5>
              </div>
            </div>

            <div className="bg-[#0504aa] text-[#fff] w-full 800px:w-[600px] mt-2 px-4 py-2 rounded-xl">
              <div className="flex justify-between">
              <h5 className="text-[16px] font-[400] w-[30%]">Phone</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[60%] px-2 py-1 rounded-xl">{user?.phoneNumber}</h5>
              </div>
            </div>

            <div className="bg-[#0504aa] text-[#fff] w-full 800px:w-[600px] mt-2 px-4 py-2 rounded-xl">
              <div className="flex justify-between">
              <h5 className="text-[16px] font-[400] w-[30%]">Country</h5>
              <h5 className="text-sm bg-[#fff] text-[#1c1c1c] w-[60%] px-2 py-1 rounded-xl">{user?.country}</h5>
              </div>
            </div>
            <br />
            <br />
            <br />

        </>
      )}

      {/* tasks */}
       {active === 2 && (
        <div>
          <br />
          <br />
          <AllTasks />
        </div>
      )}

     
      {/* Change Password */}
      {active === 3 && (
        <div>
          <br />
          <br />
          <ChangePassword />
        </div>
      )}

      {/*  user Earnings */}
      {active === 4 && (
        <div>
          <br />
          <br />
          <Earnings />
        </div>
      )}

      {/*  Edit Profile */}
      {active === 6 && (
        <div>
          <br />
          <br />
          <EditProfile />
        </div>
      )}

      
      {/*  Investment */}
      {active === 7 && (
        <div>
          <br />
          <br />
          <Investment />
        </div>
      )}  


      {/*  Leader Board */}
      {active === 8 && (
        <div>
          <br />
          <br />
          <LeadBoard />
        </div>
      )}  

      <h5 className="mt-9 mb-9">-</h5>   
      

    </div>
    </>
    
  );
};


const Earnings = () => {

  const { user } = useSelector((state) => state.user);
  const [withdrawUserUserData, setWithdrawUserUserData] = useState([]);
  const [depositData, setDepositData] = useState([]);
  const [eHistoryData, setEhistoryData] = useState([]);

  const [mycontrolData, setMycontrolData] = useState([]);

  const withUserUserId = user?._id;
  const depositUserId = user?._id;
 
  useEffect(() => {
    axios
      .get(`${server}/mycontrol/get-all-mycontrols`, {
        withCredentials: true,
      })
      .then((res) => {
        setMycontrolData(res.data.mycontrols);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

    
    useEffect(() => {
        axios
          .get(`${server}/withdrawuser/get-all-withdraw-request-user/${withUserUserId}`, {
            withCredentials: true,
          })
          .then((res) => {
            setWithdrawUserUserData(res.data.withdrawusers);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }, []);

      useEffect(() => {
        axios
          .get(`${server}/depositfb/get-all-deposit-user/${depositUserId}`, {
            withCredentials: true,
          })
          .then((res) => {
            setDepositData(res.data.depositfbs);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }, []);

      useEffect(() => {
        const dataforEarnH = user && user.earningHistories;
        setEhistoryData(dataforEarnH);
      }, []);

      
  const availableBalance = user?.availableBalance;
  const bonusBalance = user?.bonusBalance;
    
  const mcDataToUse = mycontrolData && mycontrolData.find((iiti) => iiti.controlPin === "123456dan");
  console.log(mcDataToUse);

  
  
  return (
    <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>

    <div className="w-full block 800px:flex items-center justify-between px-5">
      <div className="w-full mb-4 800px:w-[30%] h-[30vh] min-h-[25vh] border border-b-[#0504aa] border-r-[#0504aa] border-b-[8px] border-r-[8px] bg-white shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
          <img
          src={logo2}
          width='25px'
          alt=""
          />
            <h3
              className={`${styles.productTitle} !text-[16px] leading-5 !font-[400] ml-2 !text-[#0504aa]`}
            >
              Main Asset Wallet
              </h3>
          </div>
          <h5 className="text-center pt-2 text-[30px] font-[500]">N{availableBalance}</h5>
          {mcDataToUse && mcDataToUse.isPaymentDay === "false" ? (
            <>
            <h5 className="text-center pt-4 px-[2] text-[#808080]">Withdraw</h5>
            
            {user && user?.hasDeposited === "yes" ? (
                <h5 className="text-center pt-4 px-[2] text-[#808080]">Deposit</h5>
              ) : (
                <Link to="/account-deposit">
                <h5 className="text-center pt-4 px-[2] text-[#0504aa]">Deposit</h5>
                </Link>
              )}
            </>
          ) : (
            <>
            <Link to="/account-withdraw-assetbalance">
            <h5 className="text-center pt-4 pl-[2] text-[#0504aa]">Withdraw</h5>
          </Link>

              {user && user?.hasDeposited === "yes" ? (
                <h5 className="text-center pt-4 px-[2] text-[#808080]">Deposit</h5>
              ) : (
                <Link to="/account-deposit">
                <h5 className="text-center pt-4 px-[2] text-[#0504aa]">Deposit</h5>
                </Link>
              )}
            </>            
          )}
          
        </div>

        

        <div className="w-full mb-4 800px:w-[30%] h-[30vh] min-h-[25vh] border border-b-[#0504aa] border-r-[#0504aa] border-b-[8px] border-r-[8px] bg-white shadow rounded px-5 py-5">
          <div className="flex items-center justify-center">
          <img
          src={logo2}
          width='25px'
          alt=""
          />
            <h3
              className={`${styles.productTitle} !text-[16px] leading-5 !font-[400] ml-2 !text-[#0504aa]`}
            >
              Monthly Salary Wallet
              </h3>
          </div>
          <h5 className="text-center pt-2 text-[30px] font-[500]">N{bonusBalance}</h5>
          <Link to="/account-withdraw-monthlybalance">
            <h5 className="text-center pt-4 pl-[2] text-[#0504aa]">Withdraw</h5>
          </Link>
          </div>
       
       </div>
        <br />

        <div className="w-full">
        <h1 className="text-[20px] pb-3 ml-4 font-[600]">Transaction History</h1>
        </div>
        

        <div className="w-full">
        <HistoryInfo
            depositData={depositData}
            withdrawUserUserData={withdrawUserUserData}
        />
        </div>
            
          <br />
          <br />


          <div className="w-full">
        <h1 className="text-[20px] pb-3 ml-4 font-[600]">Earning History</h1>
        </div>
        

        <div className="w-full">
        <EhistoryInfo
            eHistoryData={eHistoryData}
           />
        </div>
            
          <br />
          <br />

    </div>
  )
}



const AllTasks = () => {

  const [tData, setTData] = useState([]);
  const [tData2, setTData2] = useState([]);
  const [taskTU, setTaskTU] = useState(null);
  const { user } = useSelector((state) => state.user);

  const userId = user?._id;

  
  useEffect(() => {
    axios
      .get(`${server}/task/get-user-taskdone/${userId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setTaskTU(res.data.tasks);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  useEffect(() => {
    axios.get(`${server}/task/open-all-tasks`, {withCredentials: true}).then((res) => {
      setTData(res.data.tasks);
       })
  }, []);

  useEffect(() => {
    axios.get(`${server}/videotask/get-all-videotasks`, {withCredentials: true}).then((res) => {
      setTData2(res.data.videotasks);
     
    })
  }, []);

  const taskByCountryTotal = tData && tData.filter((it) => it.targetedCountry === user?.country || it.targetedCountry === "Worldwide")

  const taskByCountryTotal2 = tData2 && tData2.filter((itu) => itu.targetedCountry === user?.country || itu.targetedCountry === "Worldwide")

  const totalTask1 = taskByCountryTotal && taskByCountryTotal.length;

  const totalTask2 = taskByCountryTotal2 && taskByCountryTotal2.length;

  const totalTask = Number(totalTask1) + Number(totalTask2);

     
  const columns = [
    { field: "id", headerName: "Task ID", minWidth: 150, flex: 0.7 },

    {
      field: "title",
      headerName: "Title",
      minWidth: 130,
      flex: 0.7,
      },
      {
      field: "payment",
      headerName: "Payment",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 130,
      flex: 0.7,
    },     
  ];

  const row = [];

  taskTU &&
  taskTU.forEach((item) => {
      row.push({
        id: item._id,
        title: item.name,
        payment: "$ " + item.taskPayment,
        date: item.updatedAt.slice(0, 10),
      });
    });

  
  return (
    <>
    <h1 className="text-[25px] font-[500]">All Tasks</h1>
    <p>Total tasks available and open to users in your location:</p>
    <h1 className="text-[20px] font-[500]">{totalTask}</h1>
    <p className="text-[15px] text-[blue]">
    Note: Make sure you have an account with the targeted task social handle before completing the task. Example: Facebook tasks will require your facebook username; etc. Your username for the targeted handles will be needed while completing each task submission. This is used to check the credibility of task completion by workers on the targeted customer's social profile. Any wrong steps might get your account suspended. Ensure you have read our terms on Task Submission Agreement before begining to submit tasks.
    </p>

    <>   
    {
      totalTask && totalTask > 0 ? (
        <Link to="/tasks">
      <button className={`${styles.buttonGold} mb-5 text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4`}>
        Begin Work
      </button>
    </Link>
      ) : (
        <div>
        <button className={`${styles.buttonGold} mb-5 text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4`}>
          Finished
        </button>
        <h5 className="text-[15px]">
        No available tasks found in your location. Kindly refresh the page or check back later.
        </h5>
        </div>
      )
    }
    </> 
   
    

    <br />
    <hr />
    <br />
    <h1 className="text-[25px] font-[500]">Tasks Payment Invoice</h1><br />
    <div className="bg-[#fff] px-8 py-8">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />
    </div>
    </>
  );
};


const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

 
  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.data.success);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  return (
    <div className="w-full px-5">
      <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
      Change Password
      </h1>
      <div className="w-full">
        <form
          aria-required
          onSubmit={passwordChangeHandler}
          className="flex flex-col items-center"
        >
          <div className=" w-[100%] 800px:w-[50%] mt-5">
            <label className="block pb-2">Enter your old password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter your new password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className=" w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter your confirm password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <input
              className={`!bg-[#0504aa] w-[95%] h-[40px] border border-[#fff] text-center text-[#fff] rounded-[3px] mt-8 cursor-pointer`}
              required
              value="Update"
              type="submit"
            />
          </div>
        </form>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};



const EditProfile = () => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user && user.name);

  const email = user?.email;
  
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  
  const [visible, setVisible] = useState(false);

  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const dispatch = useDispatch();

    
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(
      name, 
      email, 
      phoneNumber, 
      password, 
      ));
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(
            `${server}/user/update-avatar`,
            { avatar: reader.result },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            dispatch(loadUser());
            toast.success("avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };


    
  return (
    <>
    <div className="flex justify-center w-full">

            <div className="relative">
              {user?.avatar?.url === undefined ? (
                <CgProfile size={100} color="#000" className="mt-5" />
              ) : (
              <img
                src={`${user?.avatar?.url}`}
                className="w-[150px] h-[150px] rounded-full object-cover border-[3px] border-[#fff] mt-5"
                alt=""
              />)}

              <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[5px] right-[5px]">
                <input
                  type="file"
                  id="image"
                  className="hidden"
                  onChange={handleImage}
                />
                <label htmlFor="image">
                  <AiOutlineCamera />
                </label>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="w-full px-5">
          
          <form onSubmit={handleSubmit} aria-required={true}>
          

              <h2 className="text-[25px] text-[600]">Profile Details:</h2><br />
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Name:</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
               </div>
                            

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Mobile:</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>

                    <div>
                    <label className="block pb-2">Enter Password to validate the update:</label>
                    <div className="mt-1 relative">
                      <input
                        type={visible ? "text" : "password"}
                        placeholder="enter password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                      {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
                </div>
                </div>

              <input
                className={`w-[250px] h-[40px] border border-[#fff] text-center bg-[#0504aa] text-[#fff] rounded-[3px] mt-8 cursor-pointer`}
                required
                value="Update"
                type="submit"
              />
            </form>
            <br />
            <br />
            <br />
            <br />
            <h2>-</h2>
          </div>
        </>
    );
};



const Investment = () => {

  const { user } = useSelector((state) => state.user);
  const investorID = user?._id;
  const [isvisible, setIsVisible] = useState(false);
  const [mycontrolData, setMycontrolData] = useState([]);
  const [iData, setIdata] = useState([]);

  
  useEffect(() => {
    axios
      .get(`${server}/investment/get-all-investments/${investorID}`, {
        withCredentials: true,
      })
      .then((res) => {
        setIdata(res.data.investments);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);


  const refLink = user?.refLink;


  const writeTextToClipboard = (text) => {
    text = refLink;
    navigator.clipboard.writeText(text);
    toast.success("Link Copied!");
  };

  const columns = [
    { field: "id", headerName: "ID", minWidth: 150, flex: 1 },
    {
      field: "packageAmount",
      headerName: "Amount",
      type: "text",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "duration",
      headerName: "Days",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "investmentStatus",
      headerName: "Status",
      type: "text",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Date",
      type: "text",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: " ",
      flex: 0.5,
      minWidth: 150,
      headerName: "View",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/i/details/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
        );
      },
    },

  ];

  const row = [];
  
  iData &&
    iData.forEach((item) => {
      row.push({
        id: item._id,
        packageAmount: "N" + item.packageAmount,
        duration: item.duration,
        investmentStatus: item.investmentStatus,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  
  
  return (
    <div className={`${styles.section} w-[90%] 800px:w-[95%]`}>

    <h1 className="ml-2 text-[25px] font-[500]">Explore our Investment Plans or Assets</h1>
    <h5 className="ml-2 pb-4">Choose from the veriety of investment plans or assets that suit Your
      financial goals. Each asset offers unique benefits, so you can maximize your returns.
    </h5>
    
    <h5 className="ml-2 pb-4">Invest today and watch your money grow!
    </h5>
    {user?.activeInvestment === "NO-ASSET" ?  
    <Link to="/dashboard-invest" className="inline-block">
          <div className={`${styles.buttonRed} !bg-[#02066f] !ml-4 mb-6`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Invest
               </span>
          </div>
      </Link> : null
    }
    
    
        <div className="w-full block 800px:flex items-center justify-between px-5">
        <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-[35vh] shadow   rounded-xl px-2 py-2 800px:px-5 800px:py-5"
        style={{
          background:
           "linear-gradient(#000220, #02066f, #02066f)",
          }}
        >
        <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              BRAZE ASSET - 1
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N2,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N300</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N8,400</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>



          <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-[35vh] shadow  rounded-xl px-2 py-2 800px:px-5 800px:py-5"
           style={{
            background:
             "linear-gradient(#000220, #02066f, #02066f)",
            }}
          >
          <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              BRONZE ASSETS - 2
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N4,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N600</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N16,800</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>



          <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-[35vh] shadow  rounded-xl px-2 py-2 800px:px-5 800px:py-5"
           style={{
            background:
             "linear-gradient(#000220, #02066f, #02066f)",
            }}
          >
          <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              SILVER ASSETS - 3
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N10,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N1,500</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N42,000</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>
          </div>
        <br />








        <div className="w-full block 800px:flex items-center justify-between px-5">
        <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-35vh] shadow  rounded-xl px-2 py-2 800px:px-5 800px:py-5"
        style={{
          background:
           "linear-gradient(#000220, #02066f, #02066f)",
          }}
        >
        <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              GOLD ASSETS - 4
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N20,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N3,000</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N84,000</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>



          <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-[35vh] shadow  rounded-xl px-2 py-2 800px:px-5 800px:py-5"
          style={{
            background:
             "linear-gradient(#000220, #02066f, #02066f)",
            }}
          >
          <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              DIAMOND ASSETS - 5
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N50,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N7,500</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N210,000</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>



          <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-[35vh] shadow  rounded-xl px-2 py-2 800px:px-5 800px:py-5"
          style={{
            background:
             "linear-gradient(#000220, #02066f, #02066f)",
            }}
          >
          <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              EMERALD ASSETS - 6
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N100,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N15,000</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N420,000</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>
          </div>
        <br />



    <div className="w-full block 800px:flex items-center justify-between px-5">
      <div className="w-full mb-4 800px:w-[22%] h-[20vh] min-h-[20vh] shadow rounded-xl px-5 py-5"
      style={{
        background:
         "linear-gradient(#000, #014D4E, teal)",
        }}
      >
          <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] leading-5 !font-[400] text-[#fff] text-center`}
            >
              Active Asset
              </h3>
          </div>
          {user?.activeInvestment === undefined ? <h5 className="text-center text-[#fff] pt-2 pl-2 text-[30px] font-[400]">-</h5> : <h5 className="text-center text-[#fff] pt-2 pl-2 text-[30px] font-[400]">{user?.activeInvestment}</h5>}
          </div> 

        <div className="w-full mb-4 800px:w-[22%] h-[20vh] min-h-[20vh] bg-white shadow rounded-xl px-5 py-5">
          <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] text-center leading-5 !font-[400] text-[#00000085]`}
            >
              Total Investment Deposit
            </h3>
          </div>
          <h5 className="text-center pt-2 text-[30px] font-[400]">N{user?.totalInvestmentDeposit === undefined ? 0 : user?.totalInvestmentDeposit.toLocaleString()}</h5>
          </div>

        <div className="w-full mb-4 800px:w-[22%] h-[20vh] min-h-[20vh] bg-white shadow rounded-xl px-5 py-5">
          <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] text-center leading-5 !font-[400]  text-[#00000085]`}
            >
              Total Withdrawal
            </h3>
          </div>
          <h5 className="text-center pt-2 text-[30px] font-[400]">N{user?.totalInvestmentWithdrawal === undefined ? 0 : user?.totalInvestmentWithdrawal.toLocaleString()}</h5>
          </div>
          </div>
        <br />






        <div className="w-full">
        <h1 className="text-[20px] pb-3 ml-4 font-[600]">Investment Tracking</h1>
        </div>
        

        <div className="w-full bg-white rounded pb-6 mb-4">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
            
          <br />
          <br />

    </div>
  )
}


const LeadBoard = () => {
  const [topEarners, setTopEarners] = useState([]);
  const [loading, setLoading] = useState(true); // State to handle loading
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    axios
      .get(`${server}/user/top-earners`, { withCredentials: true })
      .then((res) => {
        console.log(res.data); // Log the response to verify data structure
        setTopEarners(res.data.users);
        setLoading(false); // Stop loading once data is fetched
      })
      .catch((error) => {
        console.error(error); // Log the error
        setError(error.response ? error.response.data.message : 'An error occurred');
        setLoading(false);
      });
  }, []);

  // Return loading or error message if not data available
  if (loading) {
    return <div>Loading top earners...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div>
        <img
          src={logo2}
          className="ml-3 w-[60px] h-[60px] rounded-full mr-4"
          alt=""
        />
      </div>
      <h2 className="ml-4 text-[20px] font-[500]">Top Leaders Board</h2>
      <br />

      <div>
        {topEarners && topEarners.length > 0 ? (
          topEarners.map((earner, index) => (
            <div
              key={earner._id}
              className="bg-[#0504aa] text-[#fff] w-full 800px:w-[600px] mt-3 px-4 py-2 rounded-xl"
            >
              <div className="flex justify-between ">
                <img
                  src={logo2}
                  className="w-[30px] h-[30px] rounded-full mr-1"
                  alt=""
                />
                {/* Ranking */}
                <h5 className="text-[14px] font-[400] w-[10%] text-center py-2">{index + 1}</h5>

                {/* User Name */}
                <h5 className="text-[14px] font-[400] w-[30%] py-2">{earner.username.length > 4 ? earner.username.slice(0, 4) + "." : earner.username}</h5>

                {/* Total Earnings */}
                <h5 className="text-[17px] 800px:text-[17px] font-[600] bg-[#fff] text-[#1c1c1c] w-[50%] px-2 py-2 rounded-xl overflow-x scroll">
                  N{earner.totalRefEarning.toLocaleString()}
                </h5>
              </div>
            </div>
          ))
        ) : (
          <div>No top earners found</div>
        )}
      </div>

      <br />
      <br />
      <br />
      <h5 className="mt-9 mb-9">.</h5>
      <br />
      <br />
      <br />
    </>
  );
};


export default ProfileContent;

