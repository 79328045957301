import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../styles/styles";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import Spinner from "../../src/components/Spinner/Spinner";
import { RxCross1 } from "react-icons/rx";
import tPay from '../Assests/images/logo.png';
//import { Link } from "react-router-dom";

const UserInvest = () => {
  const [open, setOpen] = useState(false);
  
 const { user } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [activeInvestment, setActiveInvestment] = useState("");
  const [damount, setDamount] = useState(0);
  const [dailyGains, setDailyGains] = useState(0);
  const [totalGains, setTotalGains] = useState(0);
  const [bonus, setBonus] = useState(0);
   
  
  const tam = user?.availableBalance;
  const email = user?.email;
  const investorCountry = user?.country;
  const packageAmount = Number(damount);
  const investorID = user?._id;
  const packageName = activeInvestment;
  const duration = 30;
  const dailyIncome = dailyGains;
  const tPluseC = totalGains;
  const referrer = user?.referrer;
  const investorName = user?.name;

  const error = () => {
    toast.error("Balance Insuficient!!");
  };

  useEffect(() => {
    if(activeInvestment === "BRAZE"){
      setDamount(2000);
      setDailyGains(300);
      setTotalGains(8400);
      setBonus(300);
    }

    if(activeInvestment === "BRONZE"){
      setDamount(4000);
      setDailyGains(600);
      setTotalGains(16800);
      setBonus(600);
    }

    if(activeInvestment === "SILVER"){
      setDamount(10000);
      setDailyGains(1500);
      setTotalGains(42000);
      setBonus(1500);
    }

    if(activeInvestment === "GOLD"){
      setDamount(20000);
      setDailyGains(3000);
      setTotalGains(84000);
      setBonus(3000);
    }

    if(activeInvestment === "DIAMOND"){
      setDamount(50000);
      setDailyGains(7500);
      setTotalGains(210000);
      setBonus(7500);
    }

    if(activeInvestment === "EMERALD"){
      setDamount(100000);
      setDailyGains(15000);
      setTotalGains(420000);
      setBonus(15000);
    }
  }, [activeInvestment]);

  console.log(damount);
  console.log(dailyIncome);
  console.log(tPluseC);
  console.log(bonus);

    
  
  const balanceInvestHandler = async (e) => {
   
    e.preventDefault();

    if (damount > tam) {
      toast.error("Balance Insuficient!");
    } else {
      setIsLoading(true);
      await axios
        .post(
          `${server}/investment/invest/via-balance`,
          { investorID, email, packageAmount, investorName, investorCountry, duration, tPluseC, referrer, dailyIncome, packageName, bonus },
          { withCredentials: true }
        )
        .then((res) => {
          setIsLoading(false);
          setOpen(false)
          toast.success("Asset Purchase Successful!");
          window.location.reload();
        });
    }
  };
  
  
  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <>
      {user?.activeInvestment === "NO-ASSET" ? (<>
      <div className="w-full h-[60vh] p-8">
      <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
      <div className="800px:w-[30%] 800px:h-[95%] bg-[#d8f9ff] w-[90%] h-[95%] rounded flex text-center items-center justify-center flex-col 800px:py-2">
        <h5 className="text-[20px] text-[blue]">
          Asset Wallet Balance: 
        </h5>
        <h2 className="text-[35px] font-[600] pb-1">
        N{user?.availableBalance === undefined ? 0 : user?.availableBalance.toLocaleString()}
        </h2>
        <h6 className="text-[15px] pb-2 mt-4">
         Choose Asset Package
        </h6>
        <div
          className={`${styles.button} text-white !h-[42px] !rounded !mt-4`}
          onClick={() => (tam < 2000 ? error() : setOpen(true))}
        >
          Purchase
        </div>
       
        <div className="mt-4">
        <img
            src={tPay}
            width='60px'
            alt=""
          />
        </div>
        </div>
        </div>



        {open && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
            <div className={`w-[85%] 800px:w-[50%] min-h-[50vh] p-3`}>
              <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-[#fff] py-4 px-4 shadow-lg sm:rounded-lg sm:px-10">

              <div className="space-y-6 items-center justify-center">

              <div className="flex justify-between px-4">
              <p className="text-[18px] font-[500] mr-6">Choose Package</p>
              <RxCross1 size={20} className="cursor-pointer" onClick={() => setOpen(false)} />
            </div>

              <form onSubmit={balanceInvestHandler}>
                    <div>
                     <div>
                      <label
                      htmlFor="activeInvestment"
                      className="block text-start text-sm font-medium text-gray-700">
                      Package:
                      </label>
                     <div className="mt-1">
                     <select
                    name="activeInvestment"
                    onChange={(e) => setActiveInvestment(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                    <option value="-">-</option>
                    <option value="BRAZE">BRAZE</option>
                    <option value="BRONZE">BRONZE</option>
                    <option value="SILVER">SILVER</option>
                    <option value="GOLD">GOLD</option>
                    <option value="DIAMOND">DIAMOND</option>
                    <option value="EMERALD">EMERALD</option>
                    </select>
                      </div>
                    </div>

                    <div>
                      <label
                      htmlFor="damount"
                      className="block text-start text-sm font-medium text-gray-700 mt-4">
                      Asset Amount N:
                      </label>
                     <div className="mt-3">
                     <h5
                        type="number"
                        placeholder="Asset Cost"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >{damount}</h5>
                      </div>
                    </div>

                    <div>
                      <label
                      htmlFor="damount"
                      className="block text-start text-sm font-medium text-gray-700 mt-4">
                      Daily Profit N:
                      </label>
                     <div className="mt-3">
                     <h5
                        type="number"
                        placeholder="Asset Cost"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >{dailyIncome}</h5>
                      </div>
                    </div>

                    <div>
                      <label
                      htmlFor="damount"
                      className="block text-start text-sm font-medium text-gray-700 mt-4">
                      Total Income N:
                      </label>
                     <div className="mt-3">
                     <h5
                        type="number"
                        placeholder="Asset Cost"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >{tPluseC}</h5>
                      </div>
                    </div>
                    </div>
                                
                <button
              className="group relative w-full h-[40px] flex justify-center mt-6 py-2 px-4 border border-transparent text-center text-sm font-medium rounded-md !text-[#fff] bg-[#0504aa]" 
             type="submit"
            >
              Confirm
            </button>
            <br />

            </form>
          </div>


        </div>
      </div>
    </div>
  </div>
)}

</div>
      </>) : <div className="flex w-full px-8 py-6 justify-center items-center">
        <h2 className="text-center text-[20px] px-6 py-4">Already Purchased an Asset</h2>
        </div>}
      
    </>)}
    </>
    
  );
};

export default UserInvest;
