import React, { useEffect, useState } from "react";
import { AiOutlineMoneyCollect } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { MdBorderClear, MdPlaylistAddCheckCircle, MdPlaylistAddCheck, } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { server } from "../../server";
import ClientHistoryInfo from "./ClientHistoryInfo";

const DashboardHero = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
 const { tasks } = useSelector((state) => state.tasks);
  const { stasks } = useSelector((state) => state.stasks);
  const [withdrawData, setWithdrawData] = useState([]);
  const [depositData, setDepositData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);

  const withdrawerUserId = seller?._id;
  const depositorId = seller?._id;
  
 
 
  useEffect(() => {
    axios
      .get(`${server}/withdraw/get-all-withdraw-request/${withdrawerUserId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setWithdrawData(res.data.withdraws);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${server}/depositfb/get-all-deposit-client/${depositorId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setDepositData(res.data.depositfbs);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);
  

  useEffect(() => {
    const dataforPaymentsH = seller && seller.paymentHistories;
    setPaymentData(dataforPaymentsH);
  }, []);

 
  const availableBalance = seller?.availableBalance.toFixed(2);
  const paymentBalance = seller?.paymentBalance;

  
  return (
    <div className="w-full p-8">
      <h3 className="text-[22px] font-Poppins pb-2">Client Dashboard {seller && seller?.role === "ProSeller" ? <span className="text-[12px] text-[blue]">Pro</span> : <span className="text-[12px] text-[blue]">Basic</span>}</h3>
      <div className="w-full block 800px:flex items-center justify-between">
        <div className="w-full mb-4 800px:w-[19%] h-[30vh] min-h-[20vh] bg-white shadow rounded px-2 py-5">
          <div className="flex items-center">
           <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              Payment Balance{" "}
              <span className="text-[16px]">(For paying freelancers)</span>
            </h3>
          </div>
          <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">${paymentBalance}</h5>
          <Link to="/dashboard-deposit-money">
            <h5 className="pt-4 pl-[2] text-[#077f9c]">Deposit Money</h5>
          </Link>
        </div>

        {seller && seller?.role === "ProSeller" && (
          <div className="w-full mb-4 800px:w-[19%] h-[30vh] min-h-[20vh] bg-white shadow rounded px-2 py-5">
          <div className="flex items-center">
            <AiOutlineMoneyCollect
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              Sales Balance{" "}
              <span className="text-[16px]">(with 5% service charge)</span>
            </h3>
          </div>
          <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">${availableBalance}</h5>
          <Link to="/dashboard-withdraw-money">
            <h5 className="pt-4 pl-[2] text-[#077f9c]">Withdraw Money</h5>
          </Link>
        </div>
        )}
        
        {seller && seller?.role === "ProSeller" && (
          <div className="w-full mb-4 800px:w-[19%] h-[30vh] min-h-[20vh] bg-white shadow rounded px-2 py-5">
          <div className="flex items-center">
            <MdBorderClear size={30} className="mr-2" fill="#00000085" />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              All Orders
            </h3>
          </div>
          <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{orders && orders.length}</h5>
          <Link to="/dashboard-orders">
            <h5 className="pt-4 pl-2 text-[#077f9c]">View Orders</h5>
          </Link>
        </div>
        )}
                       

        <div className="w-full mb-4 800px:w-[19%] h-[30vh] min-h-[20vh] bg-white shadow rounded px-2 py-5">
          <div className="flex items-center">
            <MdPlaylistAddCheck
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              My Campaigns
            </h3>
          </div>
          <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{tasks && tasks.length}</h5>
          <Link to="/dashboard-uns-tasks">
            <h5 className="pt-4 pl-2 text-[#077f9c]">View Campaigns</h5>
          </Link>
        </div>

        <div className="w-full mb-4 800px:w-[19%] h-[30vh] min-h-[20vh] bg-white shadow rounded px-2 py-5">
          <div className="flex items-center">
            <MdPlaylistAddCheckCircle
              size={30}
              className="mr-2"
              fill="#00000085"
            />
            <h3
              className={`${styles.productTitle} !text-[18px] leading-5 !font-[400] text-[#00000085]`}
            >
              All Projects
            </h3>
          </div>
          <h5 className="pt-2 pl-[36px] text-[22px] font-[500]">{stasks && stasks.length}</h5>
          <Link to="/dashboard-s-tasks">
            <h5 className="pt-4 pl-2 text-[#077f9c]">View Projects</h5>
          </Link>
        </div>

      </div>
      <br />

      
      <div className="w-full">
        <h1 className="text-[20px] pb-3 ml-4 font-[600]">Transaction History</h1>
      </div>
        

        <div className="w-full">
        <ClientHistoryInfo
            withdrawData={withdrawData}
            depositData={depositData}
            paymentData={paymentData}
        />
        </div>
            
          <br />
          <br />
      

      </div>
  );
};

export default DashboardHero;

