import React, { useState } from "react";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import YoutubeEmbed from "../components/YoutubeEmbed";
import "../components/YoutubeEmbed.css";
import GlobalFooter from '../components/GlobalFooter';


const FAQPage = () => {
  
  return (
    <div>
      <Header activeHeading={3} />
      <Faq />
      <GlobalFooter />
      </div>
  );
};

const Faq = () => {
  const [activeTab, setActiveTab] = useState(0);
 
 
  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <div className={`${styles.section} my-8`}>

      <div className="mx-15">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Help Video (HV)</h2>
      

      <h5 className="text-[18px] font-[500]">Site Navigation</h5><br />
      <div className="w-full 800px:w-[50%]">
      <YoutubeEmbed embedId="kGKQF5krIdM" />
      </div><br />
      
      </div><br /><hr /><br />

      <div className="mx-15">
      <h2 className="text-3xl font-bold text-gray-900">Live Chat (LC)</h2>
      <h5 className="text-[18px] font-[400]">Kindly click the button bellow to start a live chart section with any of our available support agents:</h5>
      <Link to="https://wa.me/2348119878259">
            <button 
            className={`${styles.button} !bg-[#0504aa] text-[#fff] justify-center items-center text-center`}
            >
               Start Chat
              </button>
            </Link>
      </div><br /><hr /><br />

      <div className="mx-15">
      <h2 className="text-3xl font-bold text-gray-900 mb-8">Frequent Ask Questions (FAQ)</h2>
      </div>

    <div className={`${styles.section} my-8`}>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex text-start items-start justify-between w-full"
            onClick={() => toggleTab(2)}
          >
            <span className="text-lg font-medium text-gray-900">
              Question 1
            </span>
            {activeTab === 2 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 2 && (
            <div className="mt-4">
              <p className="text-base text-gray-500">
               Updating in progress... 
              </p>
              <br />
              </div>
          )}
        </div>
      
      </div>
    </div>
  </div>
    
  );
};

export default FAQPage;
