import React, { useEffect } from "react";
import { AiOutlineLogin, AiOutlineMessage } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineShoppingBag, HiBell } from "react-icons/hi";
import { FaArrowsDownToPeople } from "react-icons/fa6";
import { CiMoneyBill, CiMemoPad } from "react-icons/ci";
import {
  MdOutlineAdminPanelSettings,
 // MdOutlinePassword,
  MdOutlineTrackChanges,
} from "react-icons/md";
import { TbAddressBook, TbReportMoney } from "react-icons/tb";
import { FaLink } from "react-icons/fa6";
import { TiVendorAndroid } from "react-icons/ti";
import { FaTasks, FaJoget } from "react-icons/fa";
import { RxPerson } from "react-icons/rx";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import seting from "../../Assests/images/settings.gif";
import styles from "../../styles/styles";


const ProfileSidebar = ({ setActive, active }) => {
  
  const navigate = useNavigate();
  const {user} = useSelector((state) => state.user);

  
  const logoutHandler = () => {
    axios
      .get(`${server}/user/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);        
        navigate("/");
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  return (
    <div className="!bg-[#d8f9ff] w-full">
      <div className={`${styles.section} !bg-[#d8f9ff] py-4 px-3 rounded-lg text-[#fff] shadow-lg`}>
      <div className="grid grid-cols-3 gap-[30px] md:grid-cols-6 md:gap-[30px] lg:grid-cols-8 lg:gap-[20px] xl:grid-cols-8 xl:gap-[20px]">
      
      <div
        className="flex bg-[#0504aa] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2"
        onClick={() => setActive(1)}
      >
        <RxPerson size={15} color={active === 1 ? "yellow" : ""} />
        <span
          className={`pl-3 ${
            active === 1 ? "text-[yellow]" : ""
          } text-[12px] block`}
        >
          Account
        </span>
      </div>
      
      
        <div
        className="flex bg-[#0504aa] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2"
        onClick={() => setActive(2)}
      >
        <FaTasks size={15} color={active === 2 ? "yellow" : ""} />
        <span
          className={`pl-3 ${
            active === 2 ? "text-[yellow]" : ""
          } text-sm/4 block`}
        >
          Ads-Click
        </span>
      </div>

      <div
        className="flex bg-[#0504aa] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2"
        onClick={() => setActive(3)}
      >
        <RiLockPasswordLine size={15} color={active === 3 ? "yellow" : ""} />
        <span
          className={`pl-1 ${
            active === 3 ? "text-[yellow]" : ""
          } text-sm/4 block`}
        >
          Change Password
        </span>
      </div>


      <div
        className="flex bg-[#0504aa] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2"
        onClick={() => setActive(4)}
      >
        <RxPerson size={15} color={active === 4 ? "yellow" : ""} />
        <span
          className={`pl-3 ${
            active === 4 ? "text-[yellow]" : ""
          } text-[12px] block`}
        >
          Income
        </span>
      </div>    


      {user && user.role === 'Admin' && (
        <Link to="/admin/dashboard">
        <div
          className="flex bg-[#0504aa] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2"
          onClick={() => setActive(5)}
        >
          <MdOutlineAdminPanelSettings size={15} color={active === 5 ? "yellow" : ""} />
          <span
            className={`pl-1 ${
              active === 5 ? "text-[yellow]" : ""
            } text-sm/4 block`}
          >
            Admin Dashboard
          </span>
        </div> 
        </Link>
      )}

      <div
        className="flex bg-[#0504aa] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2"
        onClick={() => setActive(6)}
      >
        <RxPerson size={15} color={active === 6 ? "yellow" : ""} />
        <span
          className={`pl-3 ${
            active === 6 ? "text-[yellow]" : ""
          } text-sm/4 block`}
        >
          Edit Profile
        </span>
      </div>

      <div
        className="flex bg-[#0504aa] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2"
        onClick={() => setActive(7)}
      >
        <CiMoneyBill size={15} color={active === 7 ? "yellow" : ""} />
        <span
          className={`pl-3 ${
            active === 7 ? "text-[yellow]" : ""
          } text-[12px] block`}
        >
          Asset
        </span>
      </div>

      <div
        className="flex bg-[teal] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2"
        onClick={() => setActive(8)}
      >
        <CiMoneyBill size={15} color={active === 8 ? "yellow" : ""} />
        <span
          className={`pl-3 ${
            active === 8 ? "text-[yellow]" : ""
          } text-[12px] block`}
        >
          Lead-Bd
        </span>
      </div>


      <div
        className="flex bg-[#0504aa] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2"
        onClick={logoutHandler}
      >
        <AiOutlineLogin size={15} color={active === 9 ? "yellow" : ""} />
        <span
          className={`pl-3 ${
            active === 9 ? "text-[yellow]" : ""
          } text-[12px] block`}
        >
          Logout
        </span>
      </div>
      
      </div>
      </div>
    </div>
  );
};

export default ProfileSidebar;
