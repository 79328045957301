import React, { useState } from "react";
import styles from "../../../styles/styles";
import { FaTasks, FaUserFriends } from "react-icons/fa";
import { RiLuggageDepositFill, RiAccountPinCircleFill, RiCustomerService2Fill } from "react-icons/ri";
import { BiMoneyWithdraw } from "react-icons/bi";
import { FaChartLine } from "react-icons/fa6";
import { GrMoney } from "react-icons/gr";
import { Link } from "react-router-dom";
import { RxCross1 } from "react-icons/rx"




const Intro = () => {

  const [wTypeOpen, setWtypeOpen] = useState(false);

  
  return (
   <div className="!bg-[#fff] w-full">
      <div className={`${styles.section} bg-[#fff] py-4 px-6`}>
        <div className="grid grid-cols-2 gap-[35px] md:grid-cols-4 md:gap-[30px] lg:grid-cols-8 lg:gap-[20px] xl:grid-cols-8 xl:gap-[20px] mb-2">

        <Link to="/account-deposit">
        <div className="w-[110px] h-[103px] bg-[#0504aa] rounded-lg shadow-lg px-5 py-3 relative items-center text-center cursor-pointer">
          <RiLuggageDepositFill className="w-full bg-[#d8f9ff] py-2 px-2 text-[30px] rounded-xl" size={40} color="#000" />
         <h5 className='text-center text-[15px] text-[#fff] mt-2'>DEPOSIT</h5>
          </div>
          </Link>

          
          <div>
          <div onClick={() => setWtypeOpen(true)} className="w-[110px] h-[103px] bg-[#0504aa] rounded-lg shadow-lg px-5 py-3 relative items-center text-center cursor-pointer">
          <BiMoneyWithdraw className="w-full bg-[#d8f9ff] py-2 px-2 text-[30px] rounded-xl" size={40} color="#000" />
         <h5 className='text-center text-[15px] text-[#fff] mt-2'>WITHDRAW</h5>
          </div>
          {wTypeOpen && <div className="mt-3">
            <Link to="/account-withdraw-assetbalance"><h5 className="w-[110px] text-[10px] font-[500] rounded-xl text-[#fff] bg-[#0504aa] px-2">Main Asset Wallet</h5></Link>
          <Link to="/account-withdraw-monthlybalance"><h5 className="w-[110px] text-[10px] font-[500] mt-2 rounded-xl text-[#fff] bg-[#0504aa] px-2">M. Salary Wallet</h5></Link>
          <RxCross1 size={14} className="cursor-pointer mt-2" onClick={() => setWtypeOpen(false)} />
            </div>}
          </div>
          

            <Link to="/incomes">
          <div className="w-[110px] h-[103px] bg-[#0504aa] rounded-lg shadow-lg px-5 py-3 relative items-center text-center cursor-pointer">
          <FaChartLine className="w-full bg-[#d8f9ff] py-2 px-2 text-[30px] rounded-xl" size={40} color="#000" />
         <h5 className='text-center text-[15px] text-[#fff] mt-2'>INCOME</h5>
          </div>
          </Link>

          <Link to="/friends">
          <div className="w-[110px] h-[103px] bg-[#0504aa] rounded-lg shadow-lg px-5 py-3 relative items-center text-center cursor-pointer">
          <FaUserFriends className="w-full bg-[#d8f9ff] py-2 px-2 text-[30px] rounded-xl" size={40} color="#000" />
         <h5 className='text-center text-[15px] text-[#fff] mt-2'>FRIENDS</h5>
          </div>
          </Link>

          <Link to="/packages">
          <div className="w-[110px] h-[103px] bg-[#0504aa] rounded-lg shadow-lg px-5 py-3 relative items-center text-center cursor-pointer">
          <GrMoney className="w-full bg-[#d8f9ff] py-2 px-2 text-[30px] rounded-xl" size={40} color="#000" />
         <h5 className='text-center text-[15px] text-[#fff] mt-2'>PACKAGES</h5>
          </div>
          </Link>

          <Link to="/ads-clicks">
          <div className="w-[110px] h-[103px] bg-[#0504aa] rounded-lg shadow-lg px-5 py-3 relative items-center text-center cursor-pointer">
          <FaTasks className="w-full bg-[#d8f9ff] py-2 px-2 text-[30px] rounded-xl" size={40} color="#000" />
          <h5 className='text-center text-sm/4 text-[#fff] mt-2'>ADS CLICKS</h5>
          </div>
          </Link>

          <Link to="/profile">
          <div className="w-[110px] h-[103px] bg-[#0504aa] rounded-lg shadow-lg px-5 py-3 relative items-center text-center cursor-pointer">
          <RiAccountPinCircleFill className="w-full bg-[#d8f9ff] py-2 px-2 text-[30px] rounded-xl" size={40} color="#000" />
         <h5 className='text-center text-[15px] text-[#fff] mt-2'>ACCOUNT</h5>
          </div>
          </Link>

          <Link to="/contact">
          <div className="w-[110px] h-[103px] bg-[#0504aa] rounded-lg shadow-lg px-5 py-3 relative items-center text-center cursor-pointer">
          <RiCustomerService2Fill className="w-full bg-[#d8f9ff] py-2 px-2 text-[30px] rounded-xl" size={40} color="#000" />
         <h5 className='text-center text-sm/4 text-[#fff] mt-2'>CUSTOMER CARE</h5>
          </div>
          </Link>

          </div>
      </div>
    </div>
    );
};

export default Intro;