import React from 'react'
import GlobalHeader from '../components/GlobalHeader';
import UserInvest from "../components/UserInvest";
import GlobalFooter from '../components/GlobalFooter';


const UserInvestPage = () => {
  return (
    <div>
    <GlobalHeader />
    <div className="flex items-start justify-between w-full">
      <UserInvest />
    </div>
    <GlobalFooter />
  </div>
  )
}

export default UserInvestPage;