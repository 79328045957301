import { React, useState, useEffect } from "react";
import { Country } from "country-state-city";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import logom from '../../Assests/images/login-logo.png';


const Singup = () => {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [uname, setUName] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [referrer, setReferrer] = useState("");
 const navigate = useNavigate();
  
    
  
  const handleSubmit = async (e) => {
   
    e.preventDefault();
    setIsLoading(true);

    try {
      // Make an API call to check if the referrer exists
      const referrerResponse = await axios.post(`${server}/user/search-user`, { referrer });
  
      if (referrerResponse.data.exists) {
        // If referrer exists, proceed with creating the user
        axios
          .post(`${server}/user/create-user`, { name, uname, email, phoneNumber, referrer, country, password })
          .then((res) => {
            setIsLoading(false);
            setName("");
            setUName("");
            setEmail("");
            setReferrer("");
            setPhoneNumber("");
            setCountry("");
            setPassword("");
            toast.success("Account created!");
            navigate("/");
            window.location.reload(true);
          })
          .catch((error) => {
            setIsLoading(false);
            toast.error(error.response.data.message);
          });
      } else {
        // If referrer does not exist, show error message
        setIsLoading(false);
        toast.error("Invalid referrer: Upline does not exist!");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error checking referrer: " + error.message);
    }
  };
  

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <>     

<div className="min-h-screen relative bg-[#0504aa] bg-cover bg-center flex flex-col justify-center items-center sm:px-6 lg:px-8"
      style={{ backgroundImage: "url('https://i.ibb.co/SVKzWWb/bg111.jpg')" }}
      >
      <div className="sm:mx-auto sm:w-full sm:max-w-md 800px:w-full mt-6">
      <img
        src={logom}
        width='120px'
        alt=""
        />
      </div>
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-4 px-8 sm:rounded-lg sm:px-10">

            <div>
            <h3
                className="group relative w-full h-[40px] flex justify-center mb-4 py-2 px-4 border border-transparent !text-6x1 font-medium rounded-md text-[#fff]"
              >
                Signup
              </h3>
            </div>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <div className="mt-1">
                <input
                  type="text"
                  name="fullName"
                  autoComplete="name"
                  placeholder="Name:"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full bg-[#fff] bg-opacity-40 px-3 py-2 rounded-xl placeholder-[#000] border border-[#00B8E5] sm:text-sm"
                />
              </div>
            </div>

            <h5 className="text-[#fff] text-[14px]">Username must be one word, no space before or after (e.g john123 or roseP)</h5>

            <div>
             <div className="mt-1">
                <input
                  type="text"
                  name="username"
                  autoComplete="username"
                  placeholder="Username:"
                  required
                  value={uname}
                  onChange={(e) => setUName(e.target.value.toLowerCase().trim())} // Force lowercase and remove whitespace
                  className="appearance-none block w-full bg-[#fff] bg-opacity-40 px-3 py-2 rounded-xl placeholder-[#000] border border-[#00B8E5] sm:text-sm"
                />
              </div>
            </div>

            <div>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Email:"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full bg-[#fff] bg-opacity-40 px-3 py-2 rounded-xl placeholder-[#000] border border-[#00B8E5] sm:text-sm"
                />
              </div>
            </div>

            <div>
              <div className="mt-1">
                <input
                  type="number"
                  name="phoneNumber"
                  autoComplete="phoneNumber"
                  placeholder="Phone:"
                  required
                  value={phoneNumber}
                  onChange={(e) =>  setPhoneNumber(e.target.value)}
                  className="appearance-none block w-full bg-[#fff] bg-opacity-40 px-3 py-2 rounded-xl placeholder-[#000] border border-[#00B8E5] sm:text-sm"
                />
              </div>
            </div>  

            <div>
             <div className="mt-1">
                <input
                  type="text"
                  name="referrer"
                  autoComplete="referrer"
                  placeholder="Ref. Username:"
                  required
                  value={referrer}
                  onChange={(e) => setReferrer(e.target.value)}
                  className="appearance-none block w-full bg-[#fff] bg-opacity-40 px-3 py-2 rounded-xl placeholder-[#000] border border-[#00B8E5] sm:text-sm"
                />
              </div>
            </div>          
          
            <div>
              <div className="mt-1">
                    <select
                      name=""
                      id=""
                      value={country}
                      placeholder="Country:"
                      onChange={(e) => setCountry(e.target.value)}
                      className="appearance-none block w-full bg-[#fff] bg-opacity-40 px-3 py-2 rounded-xl placeholder-[#000] border border-[#00B8E5] sm:text-sm"
                    >
                      <option value="" className="block border pb-2">
                       Country:
                      </option>
                      {Country &&
                        Country.getAllCountries().map((item) => (
                          <option
                            className="block pb-2"
                            key={item.isoCode}
                            value={item.isoCode}
                          >
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
              </div>

            <div>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  placeholder="Password:"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full bg-[#fff] bg-opacity-40 px-3 py-2 rounded-xl placeholder-[#000] border border-[#00B8E5] sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-xl text-[#000] bg-[#00B8E5]"
              >
                Submit
              </button>
            </div>
            <p className="text-[14px] font-[400] text-[#fff]">By clicking the submit button you have simply agreed to our <Link to="#" target="_blank"><span className="text-[#00B8E5]">terms/privacy policy</span></Link></p>
            <div className={`${styles.noramlFlex} w-full`}>
              <h4 className="text-[#fff]">Have an account?</h4>
              <Link to="/login" className="text-[#00B8E5] pl-2">
                Login
              </Link>
              </div><br />
             </form>
        </div>
      </div>
    </div>
    </>
    )}
    </>
    
  );
};

export default Singup;

