import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo1 from '../Assests/images/logo.png'
import { RxPerson } from "react-icons/rx";

const GlobalHeader = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <div className="w-full h-[80px] bg-white shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div>
        <Link to="/">
          <img
            src={logo1}
            width='110px'
            alt=""
          />
        </Link>
      </div>
      <div className="flex items-center">
        <Link to="/profile">
        <div className="flex items-center mr-4">
        <div
        className="flex bg-[#0504aa] rounded-lg shadow-lg items-center cursor-pointer h-[40px] w-[90px] px-2">
        <RxPerson size={15} color="#fff" />
        <span
          className={`pl-3 text-[#fff] text-[12px] block`}
        >
          Account
        </span>
        </div>
        </div>
        </Link>
      </div>
    </div>
  );
};

export default GlobalHeader;
