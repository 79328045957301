import React, { useState, useEffect } from "react";
import { PaystackButton } from "react-paystack";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from '../../styles/styles';
import axios from 'axios';
import { server } from "../../server";
import { dollarRate } from "../../dollarRate";

const PaystackPayments = () => {

  const [showdata, setShowData] = useState(true)
  const [orderData, setOrderData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const publicKey = "pk_live_32cba88ca2dcc9c45269a49559787aa1a6a55964";
  const dollar_rate = dollarRate;

  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
    
  }, []);

  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: user && user,
    totalPrice: orderData?.totalPrice,
  };

  //Paystack Payment Handler new
  const paystackPaymentHandler = async () => {
       
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    order.paymentInfo = {
      type: "Paystack Payment",
      status: "Completed"
    };

    await axios
    .post(`${server}/order/create-order`, order, config)
    .then((res) => {
      paywithPaystack();
      localStorage.setItem("cartItems", JSON.stringify([]));
      localStorage.setItem("latestOrder", JSON.stringify([]));
   });
  };

   
  const componentProps = {
    email: user?.email,
    amount: Math.round(orderData?.totalPrice * 100 * dollar_rate),
    publicKey,
    referenceID: +Math.floor((Math.random() * 1000000000) + 1),
    text: "Pay now",
    onSuccess: () => paystackPaymentHandler(),
    onClose: function() {
      alert('Transaction was not completed, window closed.');
    },
   
  }

  const goHome = () => {
    navigate("/order/success");
      localStorage.setItem("cartItems", JSON.stringify([]));
      localStorage.setItem("latestOrder", JSON.stringify([]));
      window.location.reload();
  }
    
  const paywithPaystack = () => {
    setShowData(false);
    goHome();
    
  }

  return (

    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      
        
      <div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Confirm Your Order
        </h2>
      </div>
      <h3 className="block text-20px font-bold text-gray-800 text-center">Secured Payment!</h3>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" >
          <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                USD/Naira Rate:
              </label>
              {showdata && 
              <div className="mt-1">
                <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                  $1USD = N{user && dollar_rate} Naira
                </h4>
               </div>
                }
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Full Name:
              </label>
              {showdata && 
              <div className="mt-1">
                <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                  {user && user?.name}
                </h4>
               </div>
                }
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Email address:
              </label>
              {showdata && 
              <div className="mt-1">
                <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                  {user && user?.email}
                </h4>
               </div>
               }
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                Amount to Pay:
              </label>
              {showdata && 
              <div className="mt-1">
                <h4 className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                  N{user && orderData?.totalPrice * dollar_rate}
                </h4>
               </div>
               }
            </div>
          </form>

          {showdata ? (
            <PaystackButton {...componentProps}
            className={`${styles.button} !bg-[#000] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`} 
            type="submit"
            
            />
          ) : (
            <div>
              <br />
            <button 
            className={`!bg-[#0fa311] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600] pl-[10px] pr-[10px]`} 
           
            onClick={goHome}
            >Payment is Completed. Click to return</button>
            <br />
            </div>
          )}
        <div>
          <br />
      
        </div>       

       
        </div>
      </div>
      </div>
    
    </div>
  )
}


const PaystackPayment = () => {
  return (
    <div>
     
      <PaystackPayments />
      
    </div>
  );
};

export default PaystackPayment;