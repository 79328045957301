import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";


const TaskDetails = () => {
    const { allTasks } = useSelector((state) => state.tasks);
   const { id } = useParams();
    const [data, setData] = useState(null);
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [taskIsStarted, setTaskIsStarted] = useState(false);
    const [hasCompleteTask, setHasCompleteTask] = useState(false);
        

    const OtherTURL = data?.targetedURL;
    const FacebookURL = data?.facebookURL;
    const TwitterURL = data?.twitterURL;
    const InstagramURL = data?.instagramURL;
    const YoutubeURL = data?.youtubeURL;
    const LinkedinURL = data?.linkedinURL;
    const TiktokURL = data?.tiktokURL;
    const GoogleURL = data?.googleURL;
    const TrustpilotURL = data?.trustpilotURL;
    const WhatsAppGURL = data?.whatsAppGroupURL;
    const TelegramURL = data?.telegramURL;
    const BoomplayURL = data?.boomPlayURL;
    const SoundCloudURL = data?.soundCloudURL;

    useEffect(() => {
        const data = allTasks && allTasks.find((i) => i._id === id);
        setData(data);
    }, [allTasks]);


    useEffect(() => {
      if(taskIsStarted === true){
        setTimeout(() => {
          setHasCompleteTask(true);
        }, 45000);
      }
    }, [taskIsStarted]);

      const userDoingTask = user?._id;

      console.log(user?.activeInvestment);

      const isDoneTaskAlready =  data && data.workers.some((item) => item['workerId'] === userDoingTask );

      const taskPayment = 150;
          
      const worker = {
        workerName: user?.name,
        workerEmail: user?.email,
        workerId: user?._id,
        taskId:data?._id, 
        taskPayment: taskPayment,
      }
      
             
      const taskUpdateHandler = async (e) => {

        if(isDoneTaskAlready){
          return toast.error("you've completed same task earlier");
        }

        e.preventDefault();

        const taskStatus = data?.taskStatus;
       
        await axios
        
              .put(
                `${server}/task/update-task-status/${id}`,
                {
                    taskStatus: taskStatus,
                    worker: worker,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Job Submited!");
                  userBalanceForTaskDoneUpdateHandler();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      const userBalanceForTaskDoneUpdateHandler = async () => {

        const userId = user?._id;

        const oldBalance = user && user?.availableBalance;
        const newPayment = taskPayment;
        const newBalance = Number(oldBalance) + Number(newPayment);
                     
    
    await axios
    
          .put(
            `${server}/user/update-user-balance-for-taskdone/${userId}`,
            {
              newBalance: newBalance,
              taskE:newPayment, 
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("You've Earned!");
              navigate('/tasks');
              window.location.reload();
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
  }




  const taskPayment2 = 300;
          
      const worker2 = {
        workerName: user?.name,
        workerEmail: user?.email,
        workerId: user?._id,
        taskId:data?._id, 
        taskPayment: taskPayment2,
      }
      
             
      const taskUpdateHandler2 = async (e) => {

        if(isDoneTaskAlready){
          return toast.error("you've completed same task earlier");
        }

        e.preventDefault();

        const taskStatus = data?.taskStatus;
       
        await axios
        
              .put(
                `${server}/task/update-task-status/${id}`,
                {
                    taskStatus: taskStatus,
                    worker: worker2,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Job Submited!");
                  userBalanceForTaskDoneUpdateHandler2();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      const userBalanceForTaskDoneUpdateHandler2 = async () => {

        const userId = user?._id;

        const oldBalance = user && user?.availableBalance;
        const newPayment = taskPayment2;
        const newBalance = Number(oldBalance) + Number(newPayment);
                     
    
    await axios
    
          .put(
            `${server}/user/update-user-balance-for-taskdone/${userId}`,
            {
              newBalance: newBalance,
              taskE:newPayment, 
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("You've Earned!");
              navigate('/tasks');
              window.location.reload();
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
  }




  const taskPayment3 = 750;
          
      const worker3 = {
        workerName: user?.name,
        workerEmail: user?.email,
        workerId: user?._id,
        taskId:data?._id, 
        taskPayment: taskPayment3,
      }
      
             
      const taskUpdateHandler3 = async (e) => {

        if(isDoneTaskAlready){
          return toast.error("you've completed same task earlier");
        }

        e.preventDefault();

        const taskStatus = data?.taskStatus;
       
        await axios
        
              .put(
                `${server}/task/update-task-status/${id}`,
                {
                    taskStatus: taskStatus,
                    worker: worker3,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Job Submited!");
                  userBalanceForTaskDoneUpdateHandler3();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      const userBalanceForTaskDoneUpdateHandler3 = async () => {

        const userId = user?._id;

        const oldBalance = user && user?.availableBalance;
        const newPayment = taskPayment3;
        const newBalance = Number(oldBalance) + Number(newPayment);
                     
    
    await axios
    
          .put(
            `${server}/user/update-user-balance-for-taskdone/${userId}`,
            {
              newBalance: newBalance,
              taskE:newPayment, 
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("You've Earned!");
              navigate('/tasks');
              window.location.reload();
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
  }




  const taskPayment4 = 1500;
          
      const worker4 = {
        workerName: user?.name,
        workerEmail: user?.email,
        workerId: user?._id,
        taskId:data?._id, 
        taskPayment: taskPayment4,
      }
      
             
      const taskUpdateHandler4 = async (e) => {

        if(isDoneTaskAlready){
          return toast.error("you've completed same task earlier");
        }

        e.preventDefault();

        const taskStatus = data?.taskStatus;
       
        await axios
        
              .put(
                `${server}/task/update-task-status/${id}`,
                {
                    taskStatus: taskStatus,
                    worker: worker4,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Job Submited!");
                  userBalanceForTaskDoneUpdateHandler4();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      const userBalanceForTaskDoneUpdateHandler4 = async () => {

        const userId = user?._id;

        const oldBalance = user && user?.availableBalance;
        const newPayment = taskPayment4;
        const newBalance = Number(oldBalance) + Number(newPayment);
                     
    
    await axios
    
          .put(
            `${server}/user/update-user-balance-for-taskdone/${userId}`,
            {
              newBalance: newBalance,
              taskE:newPayment, 
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("You've Earned!");
              navigate('/tasks');
              window.location.reload();
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
  }



  const taskPayment5 = 3750;
          
      const worker5 = {
        workerName: user?.name,
        workerEmail: user?.email,
        workerId: user?._id,
        taskId:data?._id, 
        taskPayment: taskPayment5,
      }
      
             
      const taskUpdateHandler5 = async (e) => {

        if(isDoneTaskAlready){
          return toast.error("you've completed same task earlier");
        }

        e.preventDefault();

        const taskStatus = data?.taskStatus;
       
        await axios
        
              .put(
                `${server}/task/update-task-status/${id}`,
                {
                    taskStatus: taskStatus,
                    worker: worker5,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Job Submited!");
                  userBalanceForTaskDoneUpdateHandler5();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      const userBalanceForTaskDoneUpdateHandler5 = async () => {

        const userId = user?._id;

        const oldBalance = user && user?.availableBalance;
        const newPayment = taskPayment5;
        const newBalance = Number(oldBalance) + Number(newPayment);
                     
    
    await axios
    
          .put(
            `${server}/user/update-user-balance-for-taskdone/${userId}`,
            {
              newBalance: newBalance,
              taskE:newPayment, 
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("You've Earned!");
              navigate('/tasks');
              window.location.reload();
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
  }





  const taskPayment6 = 7500;
          
      const worker6 = {
        workerName: user?.name,
        workerEmail: user?.email,
        workerId: user?._id,
        taskId:data?._id, 
        taskPayment: taskPayment6,
      }
      
             
      const taskUpdateHandler6 = async (e) => {

        if(isDoneTaskAlready){
          return toast.error("you've completed same task earlier");
        }

        e.preventDefault();

        const taskStatus = data?.taskStatus;
       
        await axios
        
              .put(
                `${server}/task/update-task-status/${id}`,
                {
                    taskStatus: taskStatus,
                    worker: worker6,
                },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Job Submited!");
                  userBalanceForTaskDoneUpdateHandler6();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      const userBalanceForTaskDoneUpdateHandler6 = async () => {

        const userId = user?._id;

        const oldBalance = user && user?.availableBalance;
        const newPayment = taskPayment6;
        const newBalance = Number(oldBalance) + Number(newPayment);
                     
    
    await axios
    
          .put(
            `${server}/user/update-user-balance-for-taskdone/${userId}`,
            {
              newBalance: newBalance,
              taskE:newPayment, 
            },
            { withCredentials: true }
          )
          .then((res) => {
              toast.success("You've Earned!");
              navigate('/tasks');
              window.location.reload();
              })
           .catch((error) => {
            toast.error(error.response.data.message);
          });
  }



      
      return (
       <>         
          <div className="bg-white">
            {data ? (
                <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
                <div className="w-full py-5">
                  
                  <div>
                    <div className="block w-full 800px:flex">
                        <div className="w-full 800px:w-[50%] pt-5">
                            <h4 className="pb-3 font-[600] text-[18px]">
                            {data.name}
                            </h4>

                            <h4 className="py-2 text-[18px] leading-8 pb-10 whitespace-pre-line">
                            {data.description}
                            </h4>

                            {OtherTURL !== "" ? (
                              <Link to={data?.targetedURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                              onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Target URL
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {FacebookURL !== "" ? (
                              <Link to={data?.facebookURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                               onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Facebook
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {TwitterURL !== "" ? (
                              <Link to={data?.twitterURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                               onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Twitter
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {InstagramURL !== "" ? (
                              <Link to={data?.instagramURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                               onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Instagram
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {YoutubeURL !== "" ? (
                              <Link to={data?.youtubeURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                               onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Youtube
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {LinkedinURL !== "" ? (
                              <Link to={data?.linkedinURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                               onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Linkedin
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}


                            {BoomplayURL !== "" ? (
                              <Link to={data?.boomPlayURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                               onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Boomplay
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}


                          {SoundCloudURL !== "" ? (
                              <Link to={data?.soundCloudURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                               onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Soundcloud
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {TelegramURL !== "" ? (
                              <Link to={data?.telegramURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                              onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Telegram
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {TiktokURL !== "" ? (
                              <Link to={data?.tiktokURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                              onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Tiktok
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {GoogleURL !== "" ? (
                              <Link to={data?.googleURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                              onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Google
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}


                            {TrustpilotURL !== "" ? (
                              <Link to={data?.trustpilotURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5 mr-2`}
                              onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              Trustpilot
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {WhatsAppGURL !== "" ? (
                              <Link to={data?.whatsAppGroupURL} target="_blank" className="inline-block"
                              >
                              <div className={`${styles.button} mt-5`}
                              onClick={() => setTaskIsStarted(true)}>
                              <span className="text-[#fff] font-[Poppins] text-[18px]">
                              WhatsApp
                              </span>
                              </div>
                              <br />
                              </Link>
                            ) : null}

                            {user && user?.activeInvestment === "BRAZE" && (
                              <div>
                              <br />
                              <h6 className="text-[15px] pb-4">
                              By clicking the "Claim" button you agree to our<span> - </span> 
                              <Link to="/task-submission-terms-and-condition" target="_blank" className="text-[18px] text-[red] pb-4">
                              terms and condition</Link> on task submission.
                              </h6>
                              {hasCompleteTask && 
                              <button
                              className={`${styles.button} !h-[42px] text-white`}
                              onClick={taskUpdateHandler}
                             >
                              Claim
                              </button>
                              }       
                            </div>
                            )}



                            {user && user?.activeInvestment === "BRONZE" && (
                              <div>
                              <br />
                              <h6 className="text-[15px] pb-4">
                              By clicking the "Claim" button you agree to our<span> - </span> 
                              <Link to="/task-submission-terms-and-condition" target="_blank" className="text-[18px] text-[red] pb-4">
                              terms and condition</Link> on task submission.
                              </h6>       
                              {hasCompleteTask && 
                              <button
                              className={`${styles.button} !h-[42px] text-white`}
                              onClick={taskUpdateHandler2}
                             >
                              Claim
                              </button>
                              }
                            </div>
                            )}




                            {user && user?.activeInvestment === "SILVER" && (
                              <div>
                              <br />
                              <h6 className="text-[15px] pb-4">
                              By clicking the "Claim" button you agree to our<span> - </span> 
                              <Link to="/task-submission-terms-and-condition" target="_blank" className="text-[18px] text-[red] pb-4">
                              terms and condition</Link> on task submission.
                              </h6>       
                              {hasCompleteTask && 
                              <button
                              className={`${styles.button} !h-[42px] text-white`}
                              onClick={taskUpdateHandler3}
                             >
                              Claim
                              </button>
                              }
                            </div>
                            )}




                            {user && user?.activeInvestment === "GOLD" && (
                              <div>
                              <br />
                              <h6 className="text-[15px] pb-4">
                              By clicking the "Claim" button you agree to our<span> - </span> 
                              <Link to="/task-submission-terms-and-condition" target="_blank" className="text-[18px] text-[red] pb-4">
                              terms and condition</Link> on task submission.
                              </h6>       
                              {hasCompleteTask && 
                              <button
                              className={`${styles.button} !h-[42px] text-white`}
                              onClick={taskUpdateHandler4}
                             >
                              Claim
                              </button>
                              }
                            </div>
                            )}



                            {user && user?.activeInvestment === "DIAMOND" && (
                              <div>
                              <br />
                              <h6 className="text-[15px] pb-4">
                              By clicking the "Claim" button you agree to our<span> - </span> 
                              <Link to="/task-submission-terms-and-condition" target="_blank" className="text-[18px] text-[red] pb-4">
                              terms and condition</Link> on task submission.
                              </h6>       
                              {hasCompleteTask && 
                              <button
                              className={`${styles.button} !h-[42px] text-white`}
                              onClick={taskUpdateHandler5}
                             >
                              Claim
                              </button>
                              }
                            </div>
                            )}




                            {user && user?.activeInvestment === "EMERALD" && (
                              <div>
                              <br />
                              <h6 className="text-[15px] pb-4">
                              By clicking the "Claim" button you agree to our<span> - </span> 
                              <Link to="/task-submission-terms-and-condition" target="_blank" className="text-[18px] text-[red] pb-4">
                              terms and condition</Link> on task submission.
                              </h6>       
                              {hasCompleteTask && 
                              <button
                              className={`${styles.button} !h-[42px] text-white`}
                              onClick={taskUpdateHandler6}
                             >
                              Claim
                              </button>
                              }
                            </div>
                            )}

                           </div>
                        </div>
                      </div>

                    </div>
                    <h5 className="mt-9 mb-9">-</h5>
                    <h5 className="mt-9 mb-9">-</h5>
                </div>
            ) : <div className="w-full py-5 px-10">
                <h1 className="text-[16px] text-[500]">Loading Task Details...!</h1>
                </div>}
            
                </div>
             </>
        );

     }

export default TaskDetails;
