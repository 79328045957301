import React, { useState, useEffect } from "react";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineArrowRight } from "react-icons/ai";
import { server } from "../server";
import { toast } from "react-toastify";
import axios from "axios";
import { Button } from "@mui/material";
import GlobalFooter from '../components/GlobalFooter';
import InvestmentStatus from "../components/InvestmentStatus";



const PackagesPage = () => {
  
  return (
    <div>
      <Header activeHeading={2} />
      <Packages />
      <GlobalFooter />
      </div>
  );
};

const Packages = () => {

    const { user } = useSelector((state) => state.user);
  const investorID = user?._id;
  const [isvisible, setIsVisible] = useState(false);
  const [mycontrolData, setMycontrolData] = useState([]);
  const [iData, setIdata] = useState([]);

  
  useEffect(() => {
    axios
      .get(`${server}/investment/get-all-investments/${investorID}`, {
        withCredentials: true,
      })
      .then((res) => {
        setIdata(res.data.investments);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);


  const refLink = user?.refLink;


  const writeTextToClipboard = (text) => {
    text = refLink;
    navigator.clipboard.writeText(text);
    toast.success("Link Copied!");
  };


  const investmentData = iData.length > 0 ? {
    startDate: iData[0].startDate,  // Start date from DB
    investmentId: iData[0]._id,     // Unique ID for the investment
    invstStat: iData[0].investmentStatus,
  } : {};

  console.log("iData is: ");
  console.log(iData[0]);

  const columns = [
    { field: "id", headerName: "ID", minWidth: 150, flex: 1 },
    {
      field: "packageAmount",
      headerName: "Amount",
      type: "text",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "duration",
      headerName: "Days",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "investmentStatus",
      headerName: "Status",
      type: "text",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Date",
      type: "text",
      minWidth: 130,
      flex: 0.5,
    },
    {
      field: " ",
      flex: 0.5,
      minWidth: 150,
      headerName: "View",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/i/details/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
        );
      },
    },

  ];

  const row = [];
  
  iData &&
    iData.forEach((item) => {
      row.push({
        id: item._id,
        packageAmount: "N" + item.packageAmount,
        duration: item.duration,
        investmentStatus: item.investmentStatus,
        createdAt: item.createdAt.slice(0, 10),
      });
    });
  

  return (
    
    <div className={`${styles.section} w-[90%] 800px:w-[95%]`}>

<h1 className="ml-2 text-[25px] font-[500]">Explore our Investment Plans or Assets</h1>
    <h5 className="ml-2 pb-4">Choose from the veriety of investment plans or assets that suit Your
      financial goals. Each asset offers unique benefits, so you can maximize your returns.
    </h5>
    
    <h5 className="ml-2 pb-4">Invest today and watch your money grow!
    </h5>
    {user?.activeInvestment === "NO-ASSET" ? 
    <Link to="/dashboard-invest" className="inline-block">
          <div className={`${styles.buttonRed} !bg-[#02066f] !ml-4 mb-6`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               Invest
               </span>
          </div>
      </Link> : null
      }
    
    
        <div className="w-full block 800px:flex items-center justify-between px-5">
        <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-[35vh] shadow   rounded-xl px-2 py-2 800px:px-5 800px:py-5"
        style={{
          background:
           "linear-gradient(#000220, #02066f, #02066f)",
          }}
        >
        <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              BRAZE ASSET - 1
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N2,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N300</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N8,400</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>



          <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-[35vh] shadow  rounded-xl px-2 py-2 800px:px-5 800px:py-5"
           style={{
            background:
             "linear-gradient(#000220, #02066f, #02066f)",
            }}
          >
          <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              BRONZE ASSETS - 2
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N4,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N600</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N16,800</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>



          <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-[35vh] shadow  rounded-xl px-2 py-2 800px:px-5 800px:py-5"
           style={{
            background:
             "linear-gradient(#000220, #02066f, #02066f)",
            }}
          >
          <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              SILVER ASSETS - 3
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N10,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N1,500</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N42,000</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>
          </div>
        <br />








        <div className="w-full block 800px:flex items-center justify-between px-5">
        <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-35vh] shadow  rounded-xl px-2 py-2 800px:px-5 800px:py-5"
        style={{
          background:
           "linear-gradient(#000220, #02066f, #02066f)",
          }}
        >
        <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              GOLD ASSETS - 4
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N20,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N3,000</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N84,000</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>



          <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-[35vh] shadow  rounded-xl px-2 py-2 800px:px-5 800px:py-5"
          style={{
            background:
             "linear-gradient(#000220, #02066f, #02066f)",
            }}
          >
          <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              DIAMOND ASSETS - 5
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N50,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N7,500</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N210,000</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>



          <div className="w-full 800px:w-[22%] mb-4 800px:h-[30vh] 800px:min-h-[30vh] h-[35vh] min-h-[35vh] shadow  rounded-xl px-2 py-2 800px:px-5 800px:py-5"
          style={{
            background:
             "linear-gradient(#000220, #02066f, #02066f)",
            }}
          >
          <div className="flex items-center justify-center">
           <h3
              className={`${styles.productTitle} !text-[15px] !font-[400] leading-5 text-center text-[#fff]`}
            >
              EMERALD ASSETS - 6
            </h3>
          </div>
        <h5 className="text-center text-[#fff] pt-1 text-[12px] font-[300]">PRICE</h5>
        <h5 className="text-center text-[#fff] text-[30px] font-[500] mb-1">N100,000</h5>

        <div className="flex w-full px-3 justify-between">
          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Daily Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N15,000</h5>
          </div>

          <div className="rounded-xl bg-[#fff] px-2 items-center text-center">
            <span className="text-[10px] text-[#1c1c1c]">Total Income</span>
            <h5 className="text-[15px] font-[500] text-[#1c1c1c]">N420,000</h5>
          </div>
        </div>
        
        <h5 className="text-center text-[#fff] pt-2 text-[12px] font-[300]">Duration: 1 Month</h5>
          
        {user?.activeInvestment === "NO-ASSET" ? <Link to="/dashboard-invest"><h5 className="text-center text-[#c89b2a] pt-2 ">Purchase Asset</h5></Link> : null}
          </div>
          </div>
        <br />



    <div className="w-full block 800px:flex items-center justify-between px-5">
      <div className="w-full mb-4 800px:w-[22%] h-[30vh] min-h-[30vh] shadow rounded-xl px-5 py-5"
      style={{
        background:
         "linear-gradient(#000, #014D4E, teal)",
        }}
      >
          <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] leading-5 !font-[400] text-[#fff] text-center`}
            >
              Active Asset
              </h3>
          </div>
          {user?.activeInvestment === undefined ? <h5 className="text-center text-[#fff] pt-2 pl-2 text-[30px] font-[400] mb-2">-</h5> : <h5 className="text-center text-[#fff] pt-2 pl-2 text-[30px] font-[400] mb-2">{user?.activeInvestment}</h5>}

          {!investmentData.startDate ? (
  <div className='w-full items-center text-[#fff]'>
    <p className='text-[15px] text-center'>Loading Asset status...</p>
  </div>
) : (
  <InvestmentStatus
    startDate={investmentData.startDate}
    investmentId={investmentData.investmentId}
    invstStat={investmentData.invstStat}
  />
)}
          
          </div> 

        <div className="w-full mb-4 800px:w-[22%] h-[20vh] min-h-[20vh] bg-white shadow rounded-xl px-5 py-5">
          <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] text-center leading-5 !font-[400] text-[#00000085]`}
            >
              Total Investment Deposit
            </h3>
          </div>
          <h5 className="text-center pt-2 text-[30px] font-[400]">N{user?.totalInvestmentDeposit === undefined ? 0 : user?.totalInvestmentDeposit.toLocaleString()}</h5>
          </div>

        <div className="w-full mb-4 800px:w-[22%] h-[20vh] min-h-[20vh] bg-white shadow rounded-xl px-5 py-5">
          <div className="flex items-center justify-center">
            <h3
              className={`${styles.productTitle} !text-[15px] text-center leading-5 !font-[400]  text-[#00000085]`}
            >
              Total Withdrawal
            </h3>
          </div>
          <h5 className="text-center pt-2 text-[30px] font-[400]">N{user?.totalInvestmentWithdrawal === undefined ? 0 : user?.totalInvestmentWithdrawal.toLocaleString()}</h5>
          </div>
          </div>
        <br />

        <div className="w-full">
        <h1 className="text-[20px] pb-3 ml-4 font-[600]">Investment Tracking</h1>
        </div>
        

        <div className="w-full bg-white rounded pb-6 mb-4">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
            
          <br />
          <br />

    </div>
  );
};

export default PackagesPage;
