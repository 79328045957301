import React from 'react'
import GlobalHeader from '../components/GlobalHeader'
import AdminSideBar from '../components/Admin/Layout/AdminSideBar'
import AllTasks from "../components/Admin/AllTasks";

const AdminDashboardTasks = () => {
  return (
    <div>
    <GlobalHeader />
    <div className="w-full flex">
      <div className="flex items-start justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <AdminSideBar active={3} />
        </div>
        <AllTasks />
      </div>
    </div>
  </div>
  )
}

export default AdminDashboardTasks