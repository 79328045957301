import React from "react";
//import styles from "../../styles/styles";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
} from "react-icons/ai";
import { RiTiktokLine, RiTelegramLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";
import wchatng from '../../Assests/images/wchatng.png';



const Footer = () => {

  
  return (
    <div className="bg-[#02066f] text-white bg-cover bg-center"
    style={{ backgroundImage: "url('https://i.ibb.co/SVKzWWb/bg111.jpg')" }}
    >       
      
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16 sm:text-center">
        <ul className="px-5 text-center sm:text-start flex sm:block flex-col items-center">
          <h1 className="mb-1 font-semibold">Eo<span className="text-[#ffe135]">nix</span></h1>
          <div className="flex items-center mt-[15px]">
           <Link to='https://t.me/eonixcommunity' target="_blank"> <RiTelegramLine size={25} className="cursor-pointer" /></Link>
            <Link to="https://www.instagram.com/eonixenterprise?igsh=MTFjdjg5dmhwNTVjcQ==" target="_blank"><AiFillInstagram
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            /></Link>
            <Link to="https://youtube.com/@eonixenterprises?si=oxxSX172cndQ2JiW" target="_blank"><AiFillYoutube
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            /></Link>
            <Link to="https://tiktok.com/@eonixenterprises" target="_blank"><RiTiktokLine
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            /></Link>
          </div><br />
         </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold">Company</h1>
          {footerProductLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

        <ul className="text-center sm:text-start">
        <p className="mt-3">Live Chat</p>
          <div className="flex">
          <div className="sm:block flex items-center justify-center w-full mt-2">

          <a href="https://wa.me/2349035733374" target="_blank" rel="noopener">
          <img
            src={wchatng}
            width='40px'
            alt=""
          />
          </a>

          <a className="ml-4" href="https://wa.me/2349025822964" target="_blank" rel="noopener">
          <img
            src={wchatng}
            width='40px'
            alt=""
          />
          </a>
          </div>

          </div>
        </ul>

        <ul className="text-center sm:text-start">
          <h1 className="mb-1 font-semibold">Support</h1>
          {footerSupportLinks.map((link,index) => (
            <li key={index}>
              <Link
                className="text-gray-400 hover:text-teal-400 duration-300
                   text-sm cursor-pointer leading-6"
                to={link.link}
              >
                {link.name}
              </Link>
            </li>
          ))}
        
        </ul>
      </div>
      
    </div>
  );
};

export default Footer;

