import React, { useState, useEffect } from 'react';
import Header from "../components/Layout/Header";
import GlobalFooter from "../components/GlobalFooter";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { server } from "../server";
import styles from "../styles/styles";
import axios from "axios";

const AdsClicks = () => {

    const [tData, setTData] = useState([]);
    const [tData2, setTData2] = useState([]);
    const [taskTU, setTaskTU] = useState(null);
    const { user } = useSelector((state) => state.user);

    const userId = user?._id;

  
  useEffect(() => {
    axios
      .get(`${server}/task/get-user-taskdone/${userId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setTaskTU(res.data.tasks);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  useEffect(() => {
    axios.get(`${server}/task/open-all-tasks`, {withCredentials: true}).then((res) => {
      setTData(res.data.tasks);
       })
  }, []);

  useEffect(() => {
    axios.get(`${server}/videotask/get-all-videotasks`, {withCredentials: true}).then((res) => {
      setTData2(res.data.videotasks);
     
    })
  }, []);

  const taskByCountryTotal = tData && tData.filter((it) => it.targetedCountry === user?.country || it.targetedCountry === "Worldwide")

  const taskByCountryTotal2 = tData2 && tData2.filter((itu) => itu.targetedCountry === user?.country || itu.targetedCountry === "Worldwide")

  const totalTask1 = taskByCountryTotal && taskByCountryTotal.length;

  const totalTask2 = taskByCountryTotal2 && taskByCountryTotal2.length;

  const totalTask = Number(totalTask1) + Number(totalTask2);

     
  const columns = [
    { field: "id", headerName: "Task ID", minWidth: 150, flex: 0.7 },

    {
      field: "title",
      headerName: "Title",
      minWidth: 130,
      flex: 0.7,
      },
      {
      field: "payment",
      headerName: "Payment",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 130,
      flex: 0.7,
    },     
  ];

  const row = [];

  taskTU &&
  taskTU.forEach((item) => {
      row.push({
        id: item._id,
        title: item.name,
        payment: "$ " + item.taskPayment,
        date: item.updatedAt.slice(0, 10),
      });
    });

  
  return (
    <div className='px-8 py-6 mb-5'>
    <h1 className="text-[25px] font-[500]">All Tasks</h1>
    <p>Total tasks available and open to users in your location:</p>
    <h1 className="text-[20px] font-[500]">{totalTask}</h1>
    <p className="text-[15px] text-[blue]">
    Note: Make sure you have an account with the targeted task social handle before completing the task. Example: Facebook tasks will require your facebook username; etc. Your username for the targeted handles will be needed while completing each task submission. This is used to check the credibility of task completion by workers on the targeted customer's social profile. Any wrong steps might get your account suspended. Ensure you have read our terms on Task Submission Agreement before begining to submit tasks.
    </p>

    <>   
    {
      totalTask && totalTask > 0 ? (
        <Link to="/tasks">
      <button className={`${styles.buttonGold} mb-5 text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4`}>
        Begin Work
      </button>
    </Link>
      ) : (
        <div>
        <button className={`${styles.buttonGold} mb-5 text-[#fff] flex items-center py-[10px] px-[10px] text-xl/4`}>
          Finished
        </button>
        <h5 className="text-[15px]">
        No available tasks found in your location. Kindly refresh the page or check back later.
        </h5>
        </div>
      )
    }
    </> 
   
    

    <br />
    <hr />
    <br />
    <h1 className="text-[25px] font-[500]">Tasks Payment Invoice</h1><br />
    <div className="bg-[#fff] px-8 py-8">
      <DataGrid
        rows={row}
        columns={columns}
        pageSize={10}
        autoHeight
        disableSelectionOnClick
      />
    </div>
    <br />
    <br />
    <br />
    <h5 className="mt-9 mb-9">-</h5>
    </div>
  );
};

const AdsClicksPage = () => {
    return (
        <div>
            <Header />
            <AdsClicks />
            <GlobalFooter />
        </div>
    )
}

export default AdsClicksPage;