import React, { useState } from "react";
import styles from "../../styles/styles";
import { DataGrid } from "@mui/x-data-grid";



const HistoryInfo = ({
    depositData,
    withdrawUserUserData,
  }) => {
   
    const [active, setActive] = useState(1);

    const columns = [
        { field: "id", 
        headerName: "Withdraw Id", 
        minWidth: 100, 
        flex: 1, 
      },
        {
          field: "name",
          headerName: "User Name",
          minWidth: 100,
          flex: 0.7,
        },
        {
          field: "amount",
          headerName: "Amount",
          minWidth: 80,
          flex: 0.6,
        },
        {
          field: "status",
          headerName: "status",
          type: "text",
          minWidth: 80,
          flex: 0.5,
        },
        {
          field: "createdAt",
          headerName: "Request given at",
          type: "number",
          minWidth: 100,
          flex: 0.6,
        },
    ];

    const row = [];

    withdrawUserUserData &&
    withdrawUserUserData.forEach((item) => {
      row.push({
        id: item._id,
        name: item.user.name,
        amount: "N" + item.amount,
        status: item.status,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

    // Now Deposit Data

    const depositcolumns = [
        { field: "id", 
        headerName: "Deposit Id", 
        minWidth: 100, 
        flex: 0.7 
      },
       {
          field: "depositType",
          headerName: "Type",
          minWidth: 60,
          flex: 0.7,
        },
        {
          field: "amount",
          headerName: "Amount",
          minWidth: 70,
          flex: 0.6,
        },
        {
          field: "status",
          headerName: "Status",
          type: "text",
          minWidth: 80,
          flex: 0.5,
        },
        {
          field: "createdAt",
          headerName: "Date",
          type: "number",
          minWidth: 100,
          flex: 0.6,
        },
    ];

    const depositrow = [];

    depositData &&
    depositData.forEach((item) => {
        depositrow.push({
        id: item._id,
        depositType: item.depositType,
        amount: "N" + item.amount,
        status: item.status,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  
    return (
      <div className="bg-[#f5f6fb] px-3 800px:px-5 py-2 rounded">
        <div className="w-full flex justify-between pt-10 pb-2">
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[16px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
              }
              onClick={() => setActive(1)}
            >
              Withdrawals
            </h5>
            {active === 1 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
          
          
          <div className="relative">
            <h5
              className={
                "text-[#000] text-[16px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
              }
              onClick={() => setActive(2)}
            >
              Deposits
            </h5>
            {active === 2 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
          
        </div>
        
        
        {active === 1 ? (
        <div className="w-full bg-white">
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
        </div>
        ) : null}
  
        {active === 2 ? (
          <div className="w-full bg-white">
          <DataGrid
            rows={depositrow}
            columns={depositcolumns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
          </div>
        ) : null}
  
        </div>
    );
  };

  export default HistoryInfo;