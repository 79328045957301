import React, { useEffect, useState} from 'react'
import GlobalHeader from '../components/GlobalHeader'
import AdminSideBar from '../components/Admin/Layout/AdminSideBar'
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../redux/actions/user";
import styles from "../styles/styles";
import { server } from "../server";
import { toast } from "react-toastify";
import axios from "axios";
import { CgProfile } from "react-icons/cg";

const AdminActionPage = () => {
  return (
    <div>
    <GlobalHeader />
    <div className="w-full flex">
      <div className="flex items-start justify-bet ween w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <AdminSideBar active={1} />
        </div>
        <Action />
      </div>
    </div>
  </div>
  )
}

const Action = () => {
  const { users } = useSelector((state) => state.user);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [affPoints, setAffPoints] = useState(0);
  const [affPoints2, setAffPoints2] = useState(0);
  const [affPoints2c, setAffPoints2c] = useState(0);
  const [action, setAction] = useState("");
  const [role, setRole] = useState("");
  
  
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);
  
 
 const userData = users && users.find((item) => item._id === id);

 const userId = userData?._id;

  const oldBalance = userData?.bonusBalance;
  const comNewBalance = Number(oldBalance) + Number(affPoints);

  const oldBalanceb = userData?.availableBalance;
  const comNewBalanceb = Number(oldBalanceb) + Number(affPoints2);

  const oldBalancebc = userData?.availableBalance;
  const comNewBalancebc = Number(oldBalancebc) - Number(affPoints2c);

  const oldTE = userData?.totalEarning;
  const newTE = Number(oldTE) + Number(affPoints);

  const oldTE2 = userData?.totalEarning;
  const newTE2 = Number(oldTE2) + Number(affPoints2);

  const oldTE2c = userData?.totalEarning;
  const newTE2c = Number(oldTE2c) + 0;

 const monthlyCommisionHandler = async (e) => {
  e.preventDefault();

  await axios

    .put(
      `${server}/user/monthly-com-payment/${userId}`,
      {
        comNewBalance: comNewBalance,
        newTE: newTE,
        affEarned: affPoints, 
      },
      { withCredentials: true }
    )
    .then((res) => {
        toast.success("User credited successfully!");
        navigate('/admin/dashboard');
        window.location.reload();
        })
     .catch((error) => {
      toast.error(error.response.data.message);
    });
};  


const manualCreditingCommisionHandler = async (e) => {
  e.preventDefault();

  await axios

    .put(
      `${server}/user/manual-com-payment/${userId}`,
      {
        comNewBalanceb: comNewBalanceb,
        newTE2: newTE2,
        affEarned2: affPoints2, 
      },
      { withCredentials: true }
    )
    .then((res) => {
        toast.success("User credited successfully!");
        navigate('/admin/dashboard');
        window.location.reload();
        })
     .catch((error) => {
      toast.error(error.response.data.message);
    });
};


const manualCreditingCommisionHandler2 = async (e) => {
  e.preventDefault();

  await axios

    .put(
      `${server}/user/manual-com-withdrawal/${userId}`,
      {
        comNewBalancebc: comNewBalancebc,
        newTE2c: newTE2c,
        affEarned2c: affPoints2c, 
      },
      { withCredentials: true }
    )
    .then((res) => {
        toast.success("User credited successfully!");
        navigate('/admin/dashboard');
        window.location.reload();
        })
     .catch((error) => {
      toast.error(error.response.data.message);
    });
};


const actionUHandler = async (e) => {
  e.preventDefault();

  await axios

    .put(
      `${server}/user/take-action/${userId}`,
      {
        action: action,
      },
      { withCredentials: true }
    )
    .then((res) => {
        toast.success("Action completed!");
        navigate('/admin/dashboard');
        window.location.reload();
        })
     .catch((error) => {
      toast.error(error.response.data.message);
    });
}

const roleHandler = async (e) => {

  e.preventDefault();

  await axios

    .put(
      `${server}/user/promote-demote-user/${userId}`,
      {
        role: role,
       },
      { withCredentials: true }
    )
    .then((res) => {
      toast.success("Role Updated!");
      navigate('/admin/dashboard');
      window.location.reload();
      })
   .catch((error) => {
    toast.error(error.response.data.message);
  });
}


    return (

      <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      
    >
      <div className={`${styles.section} w-[90%] 800px:w-[90%]`}>
        <br />
        <br />
      <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Action Page
        </h1> 
        <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          Warning! Any desicion taken here will affect the targeted user either positively or negatively. You mus ensure to check 
          the targeted user's details properly before hiting the submit button.
        </h2>
        <br />
        <hr />
        <br />

        <h3
          className={`text-[25px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
          {userData?.name} <span className='text-[15px] text-[red]'> - Targeted User</span>
        </h3>
        <br />

        <div className="flex w-full">
            <div className="relative">
              {userData?.avatar?.url === undefined ? (
                <CgProfile size={70} color='#000' className='mt-5' />
              ) : (
                <img
                src={`${userData?.avatar?.url}`}
                className="w-[100px] h-[100px] rounded-full object-cover border-[3px] border-[#fff] mt-5"
                alt=""
              />
              )}
              
            </div>
          </div>
          <br />

          <h5 className="text-[16px] mb-1">Country: {userData?.country}</h5>
          <h5 className="text-[16px] mb-1">Email: {userData?.email}</h5>
          <h5 className="text-[16px] mb-1">Phone: {userData?.phoneNumber}</h5>
          <h5 className="text-[16px] mb-1">Username: {userData?.username}</h5>
          <h5 className="text-[16px] mb-1">Acct. Status: {userData?.accountStatus}</h5>
          <h5 className="text-[16px] mb-1">Total Ref: {userData?.totalReferrers}</h5>
          <h5 className="text-[16px] mb-1">Ref Invested: {userData?.InvestedReferrers}</h5>
          <h5 className="text-[16px] mb-1">Joined: {userData?.createdAt.slice(0, 10)}</h5>

                                  
        <br />
        <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        MONTHLY SALARY DISBURSMENT:
        </h1>
        <br />

        <div className='bg-[#0504aa] p-5'>
        <div className='bg-[#d8f9ff] p-6'>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">User email:</label>
                  <input
                    type="email"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={userData?.email}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Enter Amount N:</label>
                  <input
                    type="number"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={affPoints}
                    onChange={(e) => setAffPoints(e.target.value)}
                  />
                </div>
               </div>

               <button className={`${styles.button} mt-5`}
               onClick={monthlyCommisionHandler}
               >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Submit
                 </span>
              </button>
            </div>
        </div>
      </div>
      <br />
      <br />


      <br />
        <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        FOREIGNER (MANUAL) CREDITING DISBURSMENT:
        </h1>
        <br />

        <div className='bg-[#000] p-5'>
        <div className='bg-[#d8f9ff] p-6'>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">User email:</label>
                  <input
                    type="email"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={userData?.email}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Enter Amount N:</label>
                  <input
                    type="number"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={affPoints2}
                    onChange={(e) => setAffPoints2(e.target.value)}
                  />
                </div>
               </div>

               <button className={`${styles.button} mt-5`}
               onClick={manualCreditingCommisionHandler}
               >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Submit
                 </span>
              </button>
            </div>
        </div>
      </div>
      <br />
      <br />


      <br />
        <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        FOREIGNER (MANUAL) DEDUCTING:
        </h1>
        <br />

        <div className='bg-[#000] p-5'>
        <div className='bg-[#d8f9ff] p-6'>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">User email:</label>
                  <input
                    type="email"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={userData?.email}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Enter Amount N:</label>
                  <input
                    type="number"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={affPoints2c}
                    onChange={(e) => setAffPoints2c(e.target.value)}
                  />
                </div>
               </div>

               <button className={`${styles.button} mt-5`}
               onClick={manualCreditingCommisionHandler2}
               >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Submit
                 </span>
              </button>
            </div>
        </div>
      </div>
      <br />
      <br />


      <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        SUSPEND/ACTIVATE A USER:
        </h1>
        <br />

        <div className='bg-[#0504aa] p-5'>
        <div className='bg-[#d8f9ff] p-6'>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">User email:</label>
                  <input
                    type="email"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={userData?.email}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Action</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{userData?.accountStatus}</h5>
                    <div className="mt-1">
                    <select
                    name="action"
                    onChange={(e) => setAction(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="suspended">Suspend</option>
                    <option value="active">Activate</option>
                    </select>
                  </div>
                </div>
               </div>

               <button className={`${styles.button} mt-5`}
               onClick={actionUHandler}
               >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Submit
                 </span>
              </button>
            </div>
        </div>
      </div>
      <br />
      <br />


      <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        PROMOTE/DEMOTE USER:
        </h1>
        <br />

        <div className='bg-[#0504aa] p-5'>
        <div className='bg-[#d8f9ff] p-6'>
              <div className="w-full 800px:flex block pb-3">
              <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">User email:</label>
                  <input
                    type="email"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={userData?.email}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">New Role</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{userData?.role}</h5>
                    <div className="mt-1">
                    <select
                    name="role"
                    onChange={(e) => setRole(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="Admin">Admin</option>
                    <option value="Worker">Investor</option>
                    <option value="user">User</option>
                    </select>
                  </div>
                </div>               
               </div>

               <button className={`${styles.button} mt-5`}
               onClick={roleHandler}
               >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Submit
                 </span>
              </button>
            </div>
        </div>
      </div>
      <br />
      <br />


      <h1
          className={`text-[20px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600]`}
        >
          Admin Disclaimer
      </h1> 
      <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          I hereby agreed to any action taken by Eonix Management as a result of any damage(s) caused by this desision from me.
        </h2> 
        <br />
        <br />     

        
      </div>
    </div>
  )
}

export default AdminActionPage

