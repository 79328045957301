import React, {useState } from "react";
import { useSelector } from "react-redux";
import styles from "../styles/styles";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner/Spinner";
import { RxCross1 } from "react-icons/rx";
import tPay from '../Assests/images/logo3.png';
import { Link, useNavigate } from "react-router-dom";



const UserDepositMoney2 = () => {
    const [openCardPayment, setOpenCardPayment] = useState(false); 
    const [depositorName, setDepositorName] = useState("")

    const { user } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const [damount, setDamount] = useState(0);
    const navigate = useNavigate();
    
    const assetBalance = user && user?.availableBalance.toLocaleString();


    const acctName = "John Daniel";
    const acctNo = 6823883917;
    const bankName = "Moniepoint";

    const writeTextToClipboard = (text) => {
      text = bankName;
      navigator.clipboard.writeText(text);
      toast.success("Bank Name Copied!");
    };


    const writeTextToClipboard2 = (text) => {
      text = acctNo;
      navigator.clipboard.writeText(text);
      toast.success("Acct. No Copied!");
    }; 


    const writeTextToClipboard3 = (text) => {
      text = acctName;
      navigator.clipboard.writeText(text);
      toast.success("Acct. Name Copied!");
    };    
    

    console.log(damount);

    const depositType = "Naira"
    const email = user?.email;
    const amount = Number(damount);
    const depositorId = user?._id; 
    
    const ngnDepositHandler = async () => {    

    setIsLoading(true);
      await axios
        .post(
          `${server}/depositfb/deposit-to-asset-balance`,
          { 
            amount: amount, 
            email: email,
            depositorId: depositorId, 
            depositType: depositType, 
            depositorName: depositorName,
        },
          { withCredentials: true }
        )
        .then((res) => {
          setIsLoading(false);
          toast.success("Sleep sent! Wait for aproval");
          navigate("/");
          window.location.reload();
          });
        };
      
  
  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-full h-[70vh] p-8">
      <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col">
      <div className="800px:w-[30%] 800px:h-[95%] bg-[#d8f9ff] w-[90%] h-[95%] rounded flex text-center items-center justify-center flex-col 800px:py-2">
        <h5 className="text-[20px] text-[blue]">
          Main Asset Wallet: 
        </h5>
        <h2 className="text-[35px] font-[600] pb-1">
        N{assetBalance}
        </h2>
        <h6 className="text-[13px] pb-2 text-[blue]">
          Only deposit amount you want to invest.
        </h6>

                {user && user?.hasDeposited === "yes" ? (
                
                <h5 className={`${styles.button} !bg-[#808080] text-white !h-[42px] !rounded`}
                >
                 Deposited 
                </h5>
                
              ) : (
                <>
                {user && user?.hasSentSlip === "yes" ? (
                    <h5 className={`${styles.button} w-full !bg-[#808080] text-white !h-[42px] !rounded`}
                    >
                     Slip sent waiting confirmation! 
                    </h5>
                ) : (
                    <>
                    <div>
                  <label
                      htmlFor="activeInvestment"
                      className="block text-start text-sm font-medium text-gray-700">
                      Choose Amount:
                      </label>
                     <div className="mt-1">
                     <select
                    name="damount"
                    onChange={(e) => setDamount(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 font-[600] text-[18px] text-center"
                    >
                    <option value="-">-</option>
                    <option value="2000">2000</option>
                    <option value="4000">4000</option>
                    <option value="10000">10000</option>
                    <option value="20000">20000</option>
                    <option value="50000">50000</option>
                    <option value="100000">100000</option>
                    </select>
                      </div>
                    </div>

                    <button
                    className={`${styles.button} !bg-[#000] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`} 
                    onClick={() => setOpenCardPayment(true)}
                    >Pay Now
                    </button>
                    </>
                )}

            </>
              )}
       
        <Link to="/">
        <h5 className="text-[18px] text-[blue] pt-1 mb-2">
         Cancel 
        </h5>
        </Link>
        <div>
        <img
            src={tPay}
            width='60px'
            alt="totlesoftPay"
            className="mt-9"
          />
        </div>
        </div>
        </div>


        {openCardPayment && (
          <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e] overflow-y-auto">
            <div className={`w-[85%] 800px:w-[50%] min-h-[50vh] h-[70%] p-3 mb-6`}>
              <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-[#fff] py-4 px-4 shadow-lg sm:rounded-lg border-l border-teal-700 border-l-8 sm:px-10">

              <div className="space-y-6 items-center text-center justify-center">

              <div className="flex justify-between px-4">
              <p className="text-[18px] font-[500] mr-6">🚀Secured Deposit</p>
              <RxCross1 size={20} className="cursor-pointer" onClick={() => setOpenCardPayment(false)} />
            </div>

            <div>
              <h5>Kindly make payment to the account bellow.</h5>
              <h5 className="text-[red] mt-2">This account is valid for one time. Please do not transfer multiple times.</h5>
            </div>

              <form 
                className="space-y-6" 
                onSubmit={ngnDepositHandler}
                >
                    <div className="px-3">

                    <div className="mb-4">
                      <label
                      htmlFor="damount"
                      className="block text-start text-sm font-medium text-gray-700">
                      Amount:
                      </label>
                     <div className="mt-1">
                     <h5
                        type="number"
                        className="text-start appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-[16px] font-[600]"
                      >N{damount}</h5>
                      </div>
                    </div>
                     
                     <div className="mb-4">
                      <label
                      htmlFor="damount"
                      className="block text-start text-sm font-medium text-gray-700">
                      Bank Name:
                      </label>
                      <div className="mt-1 flex justify-between">
                     <h5
                        type="number"
                        className="text-start appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-[16px] font-[600]"
                      >{bankName}</h5> <span className="rounded-lg bg-[#0504aa] text-[#fff] text-[13px] px-4 py-2 shadow-sm cursor-pointer ml-4 text-center"
                      onClick={writeTextToClipboard}
                      >copy</span>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label
                      htmlFor="damount"
                      className="block text-start text-sm font-medium text-gray-700">
                      Acct. Number:
                      </label>
                     <div className="mt-1 flex justify-between">
                     <h5
                        type="number"
                        className="text-start appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-[16px] font-[600]"
                      >{acctNo}</h5> <span className="rounded-lg bg-teal-700 text-[#fff] text-[13px] px-4 py-2 shadow-sm cursor-pointer ml-4 text-center"
                      onClick={writeTextToClipboard2}
                      >copy</span>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label
                      htmlFor="damount"
                      className="block text-start text-sm font-medium text-gray-700">
                      Acct. Name:
                      </label>
                      <div className="mt-1 flex justify-between">
                     <h5
                        type="number"
                        className="text-start appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-[16px] font-[600]"
                      >{acctName}</h5> <span className="rounded-lg bg-[#0504aa] text-[#fff] text-[13px] px-4 py-2 shadow-sm cursor-pointer ml-4 text-center"
                      onClick={writeTextToClipboard3}
                      >copy</span>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label
                      htmlFor="damount"
                      className="block text-start text-sm font-medium text-[blue]">
                      Depositor's Name: <span className="text-[red]">*</span>
                      </label>
                      <div>
                    <div className="mt-1">
                     <input
                        type="text"
                        placeholder="enter sender's name..."
                        required
                        value={depositorName}
                        onChange={(e) => setDepositorName(e.target.value)}
                        className="bg-[#F6F6F5] appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 font-[600] text-[18px]"
                      />
                      </div>
                    </div>
                    </div>

                    <div className="mb-4">
                      <label
                      htmlFor="damount"
                      className="block text-start text-sm font-medium text-gray-700">
                      Depositor's Email:
                      </label>
                     <div className="mt-1">
                     <h5
                        type="number"
                        className="text-start text-blue appearance-none block w-full px-3 py-2 border rounded-md shadow-sm border-blue-600 sm:text-sm"
                      >{user && user.email}</h5>
                      </div>
                    </div>
                    </div>
            <button
             className="group relative cursor-pointer w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#0504aa]" 
             type="submit"
            >
              Submit slip
            </button>
            </form>

            <h5 className="mb-6 mt-2 text-[blue]">.</h5>
          </div>

        </div>
      </div>
    </div>
  </div>
)}

        
</div>
    )}
    </>
    
  );
};

export default UserDepositMoney2;


//  pk_live_2c09de272a66f361bca3a5cd6b71dc655a367288

// pk_test_54dde897f3c0f41b2197845db357f839719bc78f

//new pk_live_32cba88ca2dcc9c45269a49559787aa1a6a55964

/*
const error = () => {
      toast.error("Min Deposit is N2,000");
    };
*/
