import axios from "axios";
import React, { useEffect, useState } from "react";
import { server } from "../../server";
import { DataGrid } from "@mui/x-data-grid";
import { BsPencil } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";

const AllWithdrawUser = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [withdrawData, setWithdrawData] = useState();
  const [withdrawStatus,setWithdrawStatus] = useState('Processing');
  const [isLoading, setIsLoading] = useState(false);
  const [totals, setTotals] = useState({
    totalAmount: 0,
    pendingAmount: 0,
    successfulAmount: 0
  });
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  
  useEffect(() => {
    axios
      .get(`${server}/withdrawuser/get-all-withdraw-request-user`, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data.withdrawusers);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${server}/withdrawuser/withdrawals-amount`, { withCredentials: true })
      .then((res) => {
        setTotals({
          totalAmount: res.data.totalAmount || 0,
          pendingAmount: res.data.pendingAmount || 0,
          successfulAmount: res.data.successfulAmount || 0,
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setTotals({
          totalAmount: 0,
          pendingAmount: 0,
          successfulAmount: 0,
        });
      });
  }, []);

  const formatCurrency = (amountF) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN',
    }).format(amountF);
  };

  // Filter data based on the search query (email)
  const filteredData = (data || []).filter(item =>
    item.user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );


  const totalRequests = data && data.length;
  const processingRequests = data && data.filter(request => request.status === 'Processing').length;
  const succeededRequests = data && data.filter(request => request.status === 'succeed').length;

  const columns = [
    { field: "id", headerName: "Withdraw Id", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "User Name", minWidth: 180, flex: 1.4 },
    { field: "userId", headerName: "User Id", minWidth: 180, flex: 1.4 },
    { field: "email", headerName: "Email", minWidth: 180, flex: 1.4 },
    { field: "amount", headerName: "Amount N", minWidth: 100, flex: 0.6 },
    { field: "bank", headerName: "Bank", minWidth: 180, flex: 1.4 },
    { field: "actNo", headerName: "Acct No.", minWidth: 180, flex: 1.4 },
    { field: "holder", headerName: "Acct. Name", minWidth: 180, flex: 1.4 },
    { field: "status", headerName: "status", minWidth: 80, flex: 0.5 },
    { field: "createdAt", headerName: "Request given at", minWidth: 130, flex: 0.6 },
    { field: " ",
      headerName: "Update Status",
      type: "number",
      minWidth: 130,
      flex: 0.6,
      renderCell: (params) => {

        return (
          <BsPencil
            size={20}
            className={`${params.row.status !== "Processing" ? 'hidden' : '' } mr-5 cursor-pointer`}
            onClick={() => setOpen(true) || setWithdrawData(params.row)}
          />
        );
      },
    },
  ];

  const handleSubmitU = async () => {
    setIsLoading(true);
    await axios
      .put(`${server}/withdrawuser/update-withdraw-request-user/${withdrawData.id}`,{
        userId: withdrawData.userId,
      },{withCredentials: true})
      .then((res) => {
        setIsLoading(false);
        toast.success("Withdraw request updated!");
        setData(res.data.withdrawusers);
        setOpen(false);
      });
  };

  const row = [];

  filteredData.forEach((item) => {
    // Ensure item.details exists before accessing its properties
    const bankDetails = item.details || {}; // Default to an empty object if item.details is undefined
    
    row.push({
      id: item._id,
      userId: item.user._id,
      email: item.user.email,
      name: item.user.name,
      amount: item.amount,
      bank: bankDetails.bankName || "",  // Default to an empty string if bankName is missing
      actNo: bankDetails.bankAccountNumber || "",  // Default to an empty string if bankAccountNumber is missing
      holder: bankDetails.bankHolderName || "",  // Default to an empty string if bankHolderName is missing
      status: item.status,
      createdAt: item.createdAt.slice(0, 10),
    });
  });
  
  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className="w-full flex items-center py-5 px-5 bg-[#d8f9ff] justify-center">
      <div className="w-[95%] bg-white">
      <h3 className="text-[30px] font-Poppins-600 pb-2 mt-5 ml-4">All Withdrawals</h3>
      <br />

      {/* Search Bar */}
      <div className="mb-4 px-4">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search by user email..."
                className="w-full p-2 border border-[#0504aa] rounded-md"
              />
            </div>

          {/* Statistics */}
          <div className="flex w-full justify-between mb-4 px-4">
            <div className="w-[30%] block text-center bg-[#0504aa] px-4 rounded-md">
              <span className="text-[10px] text-[#fff] mb-1">Total W-Request</span>
              <h5 className="text-[16px] text-[#fff]">{totalRequests === undefined ? 0 : totalRequests}</h5>
            </div>

            <div className="w-[30%] block text-center bg-[#0504aa] px-1 rounded-md">
              <span className="text-[10px] text-[#fff] mb-1">Total W-Pending</span>
              <h5 className="text-[16px] text-[#fff]">{processingRequests === undefined ? 0 : processingRequests}</h5>
            </div>

            <div className="w-[30%] block text-center bg-[#0504aa] px-1 rounded-md">
              <span className="text-[10px] text-[#fff] mb-1">Total W-Approved</span>
              <h5 className="text-[16px] text-[#fff]">{succeededRequests === undefined ? 0 : succeededRequests}</h5>
            </div>
          </div>
          <br />

          <div className="800px:flex block w-full justify-between px-4">
              <div className="800px:w-[30%] mb-4 w-full block text-center bg-[#0504aa] px-1 rounded-md">
                <span className="text-[10px] text-[#fff] mb-1">Total W-Amount</span>
                <h5 className="text-[16px] text-[#fff]">{totals.totalAmount !== undefined ? formatCurrency(totals.totalAmount) : formatCurrency(0)}</h5>
              </div>

              <div className="800px:w-[30%] mb-4 w-full block text-center bg-[#0504aa] px-1 rounded-md">
                <span className="text-[10px] text-[#fff] mb-1">Pending</span>
                <h5 className="text-[16px] text-[#fff]">{totals.pendingAmount !== undefined ? formatCurrency(totals.pendingAmount) : formatCurrency(0)}</h5>
              </div>

              <div className="800px:w-[30%] mb-2 w-full block text-center bg-[#0504aa] px-1 rounded-md">
                <span className="text-[10px] text-[#fff] mb-1">Confirmed</span>
                <h5 className="text-[16px] text-[#fff]">{totals.successfulAmount !== undefined ? formatCurrency(totals.successfulAmount) : formatCurrency(0)}</h5>
              </div>
            </div>

            <br />

        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
      {open && (
        <div className="w-full fixed h-screen top-0 left-0 bg-[#00000031] z-[9999] flex items-center justify-center">
          <div className="w-[50%] min-h-[40vh] bg-white rounded shadow p-4">
            <div className="flex justify-end w-full">
              <RxCross1 size={25} onClick={() => setOpen(false)} />
            </div>
            <h1 className="text-[25px] text-center font-Poppins px-3">
              Update Withdraw status
            </h1>
            <br />
            <select
              name=""
              id=""
              onChange={(e) => setWithdrawStatus(e.target.value)}
              className="w-[200px] h-[35px] border rounded"
            >
              <option value={withdrawStatus}>{withdrawData.status}</option>
              <option value="succeed">Succeed</option>
            </select>
            <button
              type="submit"
              className={`block ${styles.button} !bg-[#0504aa] text-white !h-[42px] mt-4 text-[18px]`}
              onClick={handleSubmitU}
            >
              Update
            </button>
          </div>
        </div>
      )}
    </div>
    )
    }
    </>
    
  );
};

export default AllWithdrawUser;
